import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralNotification, Notification} from '../../state/notification.model';
import {ID} from '@datorama/akita';
import {NotificationComponent} from '../notification.component';

@Component({
  selector: 'lng-general-notification',
  templateUrl: './general-notification.component.html',
  styleUrls: ['./general-notification.component.scss']
})
export class GeneralNotificationComponent extends NotificationComponent<GeneralNotification> {}
