import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentListComponent} from './document-list.component';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {IsLoggedIn} from '../../services/auth-guard/auth-guard.service';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {DocumentEditModalComponent} from './document-edit-modal/document-edit-modal.component';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NgStackFormsModule} from '@ng-stack/forms';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {ModalModule} from '../../modules/modal/modal.module';
import {makeModalProvider} from '../../modules/modal/modal.providers';
import {DOCUMENT_EDIT_MODAL_ID} from './document-edit-modal/document-edit-modal.modal';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {ReactiveFormsModule} from '@angular/forms';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTypographyModule} from 'ng-zorro-antd/typography';

@NgModule({
  declarations: [
    DocumentListComponent,
    DocumentEditModalComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        NzCardModule,
        NzGridModule,
        NzIconModule,
        NzTagModule,
        RouterModule.forChild([
            {path: 'library/:category', component: DocumentListComponent, canActivate: [IsLoggedIn], pathMatch: 'full'},
            {path: 'library', redirectTo: 'library/'},
        ]),
        NgStackFormsModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,
        ReactiveFormsModule,
        ModalModule,
        NzButtonModule,
        NzAlertModule,
        NzSkeletonModule,
        NzTypographyModule
    ],
  exports: [
    DocumentListComponent
  ],
  entryComponents: [
    DocumentEditModalComponent
  ],
  providers: [
    makeModalProvider(DOCUMENT_EDIT_MODAL_ID, DocumentEditModalComponent)
  ]
})
export class DocumentListModule {
}
