import { Query } from '@datorama/akita';
import { EditorStore} from './editor.store';
import {Injectable} from '@angular/core';
import {EditorState} from './editor.model';

@Injectable({providedIn: 'root'})
export class EditorQuery extends Query<EditorState> {

  constructor(protected store: EditorStore) {
    super(store);
  }

}
