import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {EbookWordState} from './ebook-word.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'EbookWord', resettable: true, idKey: 'id' })
export class EbookWordStore extends EntityStore<EbookWordState> {
  constructor() {
    super({
      document: null,
      characters: [],
      loading: false,
      error: null,
      processingError: null,
      step: 0
    });
  }

}
