<nz-page-header class="editor-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title>
    <ng-container *ngIf="(document$ | async) as document">
      <ng-container *ngIf="document.category">
        <a routerLink="/library/{{document.category}}">{{document.category}}</a>&nbsp;/&nbsp;
      </ng-container>
        {{document.caption}}
    </ng-container>
  </nz-page-header-title>
  <nz-page-header-tags>
    <nz-tag *ngIf="(document$ | async)?.locked && !loading" nzColor="red" nz-tooltip nzTooltipPlacement="bottom"
            i18n-nzTooltipTitle nzTooltipTitle="This document is locked. In order to select words from it unlock it first"
            i18n>Locked</nz-tag>
    <nz-tag *ngFor="let tag of (document$ | async)?.tags" nzColor="green">{{tag}}</nz-tag>
  </nz-page-header-tags>
  <nz-page-header-extra>
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="(document$ | async) as document">
        <a nz-button nzType="default" [attr.href]="'/api/documents/' + document.id + '/words/anki_deck'"
           target="_blank" nz-tooltip nzTooltipPlacement="bottom"
           i18n-nzTooltipTitle
           nzTooltipTitle="Export Vocabulary to the ANKI deck, which can be imported to Anki flashcards program">
          <i nz-icon nzType="download"></i> Export Vocabulary
        </a>
        <button nz-button nzType="default" (click)="editDocument(document)"
                *ngIf="!document.locked"
                nz-tooltip nzTooltipPlacement="bottom"
                i18n-nzTooltipTitle
                nzTooltipTitle="Edit document's caption, category & tags">
          <i nz-icon nzType="edit"></i>
        </button>
        <a nz-button nzType="default" nz-tooltip nzTooltipPlacement="bottom"
           *ngIf="document.source"
           target="_blank"
           [attr.href]="document.source"
           i18n-nzTooltipTitle
           nzTooltipTitle="Navigate to the original document">
          <i nz-icon nzType="link"></i>
        </a>
        <button nz-button nzType="default" (click)="deleteDocument(document.id)"
                *ngIf="!document.locked"
                nz-tooltip nzTooltipPlacement="bottom"
                i18n-nzTooltipTitle
                nzTooltipTitle="Permanently delete this document">
          <i nz-icon nzType="delete"></i>
        </button>
        <button nz-button nzType="default" (click)="toggleLock()"
                nz-tooltip nzTooltipPlacement="bottom"
                i18n-nzTooltipTitle
                nzTooltipTitle="Lock / Unlock document (disallow accidental editions)">
          <i nz-icon nzType="lock" *ngIf="!document.locked"></i>
          <i nz-icon nzType="unlock" *ngIf="document.locked"></i>
        </button>
      </ng-container>
    </ng-container>
  </nz-page-header-extra>
</nz-page-header>
<div class="working-article">
  <nz-skeleton style="max-width: 800px; margin-right: auto; margin-left: auto; margin-top: 25px;"
               [nzParagraph]="{rows: 15}" [nzLoading]="loading" [nzActive]="true"></nz-skeleton>
  <main #contentElement [hidden]="loading" class="main">
  </main>
</div>
<!--<div [hidden]="hideError$ | async">-->
<!--  ERROR-->
<!--</div>-->
