import { Store, StoreConfig } from '@datorama/akita';
import {Injectable} from '@angular/core';

export interface PreferencesState {
  lastDocumentId?: number;
  lastDocumentScroll?: number;
}

export function createInitialState(): PreferencesState {
  return {
  };
}

@Injectable()
@StoreConfig({ name: 'preferences' })
export class PreferencesStore extends Store<PreferencesState> {

  constructor() {
    super(createInitialState());
  }

}

export const preferencesStore = new PreferencesStore();

