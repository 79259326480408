<form [formGroup]="form" (ngSubmit)="submit()">
  <ext-generic-modal [buttonX]="true" [modalId]="registeredId">
    <div modal-header i18n>Edit document</div>
    <div modal-body>
      <nz-alert *ngIf="document.locked" nzType="warning" nzMessage="This document has been locked, unlock it to edit" i18n-nzMessage nzShowIcon></nz-alert>
      <br />
      <form nz-form [formGroup]="form">
        <nz-form-item>
          <nz-form-label [nzSpan]="6" nzFor="caption" i18n>Caption</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <input nz-input name="caption" type="caption" id="caption" autocomplete="off" formControlName="caption">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="6" nzFor="category" i18n>Category</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <nz-select formControlName="category" nzMode="tags" nzAllowClear nzPlaceHolder="Choose" [nzLoading]="categoriesLoading$ | async">
              <nz-option *ngFor="let category of (categories$ | async)" [nzValue]="category.tag" [nzLabel]="category.tag"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="6" nzFor="tags" i18n>Tags</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <nz-select nzMode="tags" formControlName="tags" [nzLoading]="tagsLoading$ | async">
              <nz-option *ngFor="let tag of (tags$ | async)" [nzLabel]="tag.tag" [nzValue]="tag.tag"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div modal-footer>
      <ng-container *ngIf="!document.locked; else okRef">
      <button type="button" (click)="dismiss()" nz-button nzType="default" i18n>Cancel</button>
      <button type="submit" nz-button nzType="primary" [nzLoading]="loading$ | async" i18n>Save</button>
      </ng-container>
      <ng-template #okRef>
        <button type="button" (click)="dismiss()" nz-button nzType="default" i18n>Ok</button>
      </ng-template>
    </div>
  </ext-generic-modal>
</form>
