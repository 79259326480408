import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { WordDetailsStore, WordDetailsState } from './word-details.store';
import {Observable} from 'rxjs';
import {WordListQuery} from '../../../word-list/state/word-list.query';

@Injectable({ providedIn: 'root' })
export class WordDetailsQuery extends Query<WordDetailsState> {

  constructor(protected store: WordDetailsStore, private wordListQuery: WordListQuery) {
    super(store);
  }

  selectShow(): Observable<boolean> {
    return this.wordListQuery.select(state => state.editedWordId !== null);
  }
}
