import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concat'
})
export class ConcatPipe implements PipeTransform {

  transform(value: any[], args: string = ', '): any {
    return (value || []).join(args);
  }

}
