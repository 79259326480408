<div class="language-selector">
  <ng-container *ngFor="let language of languages">
    <i [ngClass]="{active: language === value, disabled: disabled}"
       nz-tooltip
       nzTooltipPlacement="bottom"
       [nzTooltipTitle]="language | languageName"
       (click)="change(language)"
       nz-icon
       [nzType]="language"></i>
  </ng-container>
</div>
