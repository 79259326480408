import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentComponent} from './document.component';
import {SharedModule} from '../../shared/shared.module';
import {DocumentListModule} from '../document-list/document-list.module';
import {WordListModule} from './word-list/word-list.module';
import {RouterModule} from '@angular/router';
import {WordDetailsModule} from './word-details/word-details.module';
import {IsDocumentReady} from './state/guards.service';
import {DocumentQuery} from './state/document.query';
import {DocumentStore} from './state/document.store';
import {DocumentService} from './state/document.service';
import {CharacterDetailsModule} from './character-details/character-details.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzTableModule} from 'ng-zorro-antd/table';
import {EditorModule} from './editor/editor.module';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {DetailsComponent} from './details/details.component';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzResultModule} from 'ng-zorro-antd/result';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {IsLoggedIn} from '../../services/auth-guard/auth-guard.service';

@NgModule({
  declarations: [
    DocumentComponent,
    DetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DocumentListModule,
    WordListModule,
    WordDetailsModule,
    CharacterDetailsModule,
    NzLayoutModule,
    NzTableModule,
    EditorModule,
    NzDrawerModule,
    NzModalModule,
    NzFormModule,
    NzProgressModule,
    NzTypographyModule,
    NzResultModule,
    NzButtonModule,
    NzIconModule,
    RouterModule.forChild([
      {path: 'documents/:id', component: DocumentComponent, canActivate: [IsLoggedIn]}
    ])
  ],
  providers: [
    DocumentQuery,
    DocumentService,
    DocumentStore,
    IsDocumentReady
  ],
  exports: [
    DocumentComponent
  ],
  entryComponents: [
    DetailsComponent
  ]
})
export class DocumentModule {
}
