import {PreferencesState, PreferencesStore} from './preferences.store';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {DocumentQuery} from '../../views/document/state/document.query';

@Injectable()
export class PreferencesService {

  constructor(private preferencesStore: PreferencesStore,
              private http: HttpClient,
              private documentQuery: DocumentQuery,
              @Inject(DOCUMENT) private dom: HTMLDocument) {}

  setScrollPosition(position: number) {
    this.preferencesStore.update(state => {
      this.dom.cookie = 'lastDocumentId=' + this.documentQuery.getValue().id;
      this.dom.cookie = 'lastDocumentScroll=' + position;
      return {...state, lastDocumentId: this.documentQuery.getValue().id, lastDocumentScroll: position}
    });
  }

  getCookie(cname): string {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(this.dom.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  loadPreferences() {
    this.preferencesStore.update(state => ({...state, lastDocumentId: parseInt(this.getCookie('lastDocumentId')) || undefined,
      lastDocumentScroll: parseInt(this.getCookie('lastDocumentScroll')) || undefined}));

    // this.http.get<PreferencesState>('/api/preferences/').subscribe(preferences => {
    //   this.preferencesStore.setState(() => preferences);
    // });
  }
}
