<nz-layout *ngIf="error$ | async as error; else documentRef">
  <nz-content>
    <p>Error occurred while loading document <a>retry</a></p>
  </nz-content>
</nz-layout>

<ng-template #documentRef>
  <nz-layout
    *ngIf="(document$ | async) === null || (document$ | async).state === DocumentStatus.DONE; else extraMessagesRef">
    <nz-content>
      <lng-editor [document]="document$ | async" [words]="words$ | async" [loading]="loading$ | async"></lng-editor>
    </nz-content>
    <nz-sider class="word-list-sidebar" nzTheme="light" [nzWidth]="500">
      <lng-word-list [selected]="selectedWord$ | async"
                     style="height: 100%;"
                     [disabled]="documentLocked$ | async"
                     [documentId]="(document$ | async)?.id"
                     [editedWord]="editedWord$ | async"
                     [loading]="(loading$ | async) || (wordLoading$ | async)"
                     [addingWord]="addingWord$ | async"
                     [activeWord]="activeWord$ | async"></lng-word-list>
    </nz-sider>
  </nz-layout>
</ng-template>

<ng-template #extraMessagesRef>
  <ng-container *ngIf="(document$ | async) as document">
    <nz-layout [ngSwitch]="document.state">
      <nz-content *ngSwitchCase="DocumentStatus.ERROR" class="message-layout">
        <div>
          <nz-result
            i18n-nzTitle
            nzTitle="Processing Failed"
            nzStatus="error"
            i18n-nzSubTitle
            nzSubTitle="There was a problem during processing of the document, you can delete it and try again">
            <div nz-result-extra>
              <button nz-button nzType="default" routerLink="/library" i18n>Back to Library</button>
              <button nz-button nzType="danger" (click)="deleteDocument()" i18n>Delete Document</button>
            </div>
          </nz-result>
        </div>
      </nz-content>
      <nz-content *ngSwitchCase="DocumentStatus.PROCESSING" class="message-layout">
        <div>
          <nz-progress [nzPercent]="processingProgress" nzType="circle"></nz-progress>
          <p nz-typography nzType="secondary" style="margin-top: 10px;" i18n>Your file is being processed...</p>
          <p nz-typography nzType="secondary" i18n>You can exit this page, and return later.</p>
        </div>
      </nz-content>
    </nz-layout>
  </ng-container>
</ng-template>
