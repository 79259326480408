<div class="ant-modal-content">
  <button *ngIf="!!buttonX" (click)="dismiss()" aria-label="Close" class="ant-modal-close ng-tns-c384-64 ng-star-inserted">
    <span
      class="ant-modal-close-x">
      <i nz-icon="" class="anticon ant-modal-close-icon anticon-close ng-star-inserted">
        <svg
          viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="close"
          aria-hidden="true">
          <path
            d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
        </svg>
      </i>
    </span>
  </button>
  <div class="ant-modal-header ng-tns-c384-64 ng-star-inserted" style="">
    <div class="ant-modal-title">
      <ng-content select="[modal-header='']"></ng-content>
    </div>
  </div>
  <div class="ant-modal-body">
    <ng-content select="[modal-body='']"></ng-content>
  </div>
  <div  class="ant-modal-footer ng-tns-c384-64 ng-star-inserted" style="">
    <ng-content select="[modal-footer='']"></ng-content>
  </div>
</div>
