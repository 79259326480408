import { Store, StoreConfig } from '@datorama/akita';
import {Injectable} from '@angular/core';

export type ViewMode = 'mobile'|'desktop';

export interface GeneralState {
  sidebarCollapsed: boolean;
  viewMode: ViewMode;
  showDocumentList: boolean;
  showWordList: boolean;
  routeId?: string;
}

export function createInitialState(): GeneralState {
  return {
    sidebarCollapsed: false,
    viewMode: 'desktop',
    showDocumentList: true,
    showWordList: true
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'GeneralState' })
export class GeneralStateStore extends Store<GeneralState> {

  constructor() {
    super(createInitialState());
  }

}

