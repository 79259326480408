import { QueryEntity } from '@datorama/akita';
import { WordListStore, WordListState } from './word-list.store';
import { Word } from './word-list.model';
import {Injectable} from '@angular/core';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WordListQuery extends QueryEntity<WordListState, Word> {
  constructor(protected store: WordListStore) {
    super(store);
  }

  selectEdited(): Observable<Word|null> {
    return this.select(state => state.editedWordId)
      .pipe(switchMap(wordId => this.selectEntity(wordId)));
  }

  getEdited(): Word|null {
    return this.getEntity(this.getValue().editedWordId);
  }
}
