import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationOutletComponent} from './components/notification-outlet/notification-outlet.component';
import {GeneralNotificationComponent} from './components/general-notification/general-notification.component';
import {NotificationQuery} from './state/notification.query';
import {NotificationService} from './state/notification.service';
import {DynamicNotificationComponent} from './components/dynamic-notification/dynamic-notification.component';
import {NotificationStore} from './state/notification.store';
import {DefaultNotificationClazz, NotificationAction, NotificationClazz} from './notification.tokens';
import {environment} from '../../../environments/environment';
import {devConnectNotifications} from './utils/utils';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    NotificationOutletComponent,
    GeneralNotificationComponent,
    DynamicNotificationComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    BrowserAnimationsModule
  ],
  exports: [
    NotificationOutletComponent
  ],
  providers: [
    NotificationStore,
    NotificationQuery,
    NotificationService,
    {
      provide: NotificationAction,
      useFactory: (notificationService: NotificationService) => ['dismiss', (notification) => notificationService.remove(notification.id)],
      multi: true,
      deps: [NotificationService]
    },
    {provide: NotificationClazz, useValue: {name: 'GeneralNotificationComponent', component: GeneralNotificationComponent}, multi: true},
    {provide: DefaultNotificationClazz, useValue: GeneralNotificationComponent}
  ],
  entryComponents: [
    GeneralNotificationComponent
  ]
})
export class NotificationModule {
  constructor(notificationService: NotificationService, notificationQuery: NotificationQuery) {
    if (!environment.production) {
      devConnectNotifications(notificationService, notificationQuery);
    }
  }
}
