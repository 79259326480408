import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {setLoading} from '@datorama/akita';
import {tap} from 'rxjs/operators';
import {ImportedDocumentStub} from './document-stub.model';
import {DocumentStubStore} from './document-stub.store';
import {Observable} from 'rxjs';
import {NzModalService} from 'ng-zorro-antd/modal';

@Injectable({providedIn: 'root'})
export class DocumentStubService {

  constructor(private store: DocumentStubStore, private http: HttpClient, private nzModalService: NzModalService) {
  }


  loadDocuments$(): Observable<ImportedDocumentStub[]> {
    return this.http.get<ImportedDocumentStub[]>('/api/document-stubs/')
      .pipe(
        setLoading(this.store),
        tap(entities => this.store.set(entities))
      );
  }

  deleteDocument(documentId: number) {
    this.nzModalService.confirm({
      nzTitle: 'Delete document',
      nzContent: 'Are you sure you want to permanently remove this document?',
      nzOnOk: () =>
        this.http.delete(`/api/document-stubs/${documentId}/`).pipe(
          tap(() => this.store.remove(documentId))
        ).toPromise()
    });
  }

  add(document: ImportedDocumentStub) {
    this.store.add(document);
  }

  remove(documentId: number) {
    this.store.remove(documentId);
  }
}
