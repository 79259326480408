import {Component, OnDestroy, OnInit} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {EBookService} from './state/ebook/ebook-stub.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EBookStub} from './state/ebook/ebook-stub.model';
import {EBookQuery} from './state/ebook/ebook-stub.query';
import {Router} from '@angular/router';
import {EbookWordQuery} from './state/word/ebook-word.query';
import {EbookWordService} from './state/word/ebook-word.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NzModalService} from 'ng-zorro-antd/modal';
import {EbookWord, EbookWordExtended} from './state/word/ebook-word.model';
import {WordListService} from '../document/word-list/state/word-list.service';
import {CharacterService} from '../document/word-details/state/character/character.service';
import {HlCharacter} from './word-selection/word2-characters.pipe';
import {NzDrawerRef, NzDrawerService} from 'ng-zorro-antd/drawer';
import {WordListQuery} from '../document/word-list/state/word-list.query';
import {CharacterQuery} from '../document/word-details/state/character/character.query';

@UntilDestroy()
@Component({
  selector: 'lng-kindle',
  templateUrl: './kindle.component.html',
  styleUrls: ['./kindle.component.scss']
})
export class KindleComponent implements OnInit, OnDestroy {
  public vocabularyFile?: File;
  public ebookFile?: File;
  public currentStep$ = this.ebookWordQuery.select('step');
  ebooks$: Observable<EBookStub[]> = this.eBookQuery.selectAll();
  selectedBook$: Observable<EBookStub> = this.eBookQuery.selectActive();
  uploadingVocab$ = this.eBookQuery.selectLoading();
  uploadProgress$ = this.eBookQuery.select('uploadProgress');
  errors$ = this.eBookQuery.selectError();
  errorsDocument$ = this.ebookWordQuery.selectError();
  importedWords$ = this.ebookWordQuery.selectWithWords();
  processingDocument$ = this.ebookWordQuery.selectProcessing();
  wordsLoading$ = this.ebookWordQuery.selectLoading();
  processingError$ = this.ebookWordQuery.selectProcessingError();
  ebook$ = this.ebookWordQuery.select('document');
  characters$ = this.characterQuery.selectAll();
  private drawerRef: NzDrawerRef = null;

  constructor(private eBookService: EBookService,
              private eBookQuery: EBookQuery,
              private ebookWordQuery: EbookWordQuery,
              private ebookWordService: EbookWordService,
              private nzModalService: NzModalService,
              private drawerService: NzDrawerService,
              private wordListQuery: WordListQuery,
              private wordListService: WordListService,
              private characterService: CharacterService,
              private characterQuery: CharacterQuery,
              private router: Router) {
  }

  ngOnInit(): void {
    this.ebookWordService.connectQueryToDocument().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(): void {
    this.reset();
  }

  onVocabularySelection($event: Event | NgxFileDropEntry[]) {
    fromPromise(this._handleFileSelection($event)).pipe(
      tap(file => this.vocabularyFile = file),
      switchMap(file => this.eBookService.checkVocabularyDB(file))
    ).subscribe();
  }

  selectBook(ebook: EBookStub) {
    // this.eBookService.setActive(ebook);
    this.eBookService.createEbook(ebook.id, this.vocabularyFile, this.ebookFile).subscribe();
  }

  uploadSourceEbook() {

  }

  reset() {
    this.vocabularyFile = undefined;
    this.ebookFile = undefined;
    this.eBookService.reset();
    this.ebookWordService.reset();
  }

  reloadDocument() {
    this.ebookWordService.reloadDocument().subscribe();
  }

  deleteDocument() {
    this.nzModalService.confirm({
      nzTitle: 'Delete document',
      nzContent: 'Are you sure you want to permanently remove this document?',
      nzOnOk: () => this.ebookWordService.deleteDocument().toPromise()
    });
  }

  acceptWord($event: EbookWord) {
    this.ebookWordService.acceptWord($event.id).subscribe();
  }

  rejectWord($event: EbookWord) {
    this.ebookWordService.rejectWord($event.id).subscribe();
  }

  toggleCharacter($event: HlCharacter) {
    const language = this.ebookWordQuery.getValue()?.document?.language;
    ($event.present
      ? this.characterService.remove$($event.literal, language)
      : this.characterService.add$($event.literal, language)
    ).subscribe();
  }

  finalizeDocument() {
    this.ebookWordService.finalizeDocument().subscribe();
  }

  editWord(word: EbookWordExtended) {
    this.wordListService.editWord(word.wordId);
  }

  private async _handleFileSelection($event: Event | NgxFileDropEntry[]): Promise<File> {
    return new Promise((resolve, reject) => {
      if (Array.isArray($event)) {
        ($event[0].fileEntry as FileSystemFileEntry).file(file => {
          resolve(file);
        });
      } else if ($event instanceof Event && $event.target instanceof HTMLInputElement) {
        resolve($event.target.files[0]);
      } else {
        reject(new Error('$event is not of type (Event | NgxFileDropEntry[]) or $event.target is not of type HTMLInputElement'));
      }
    });
  }
}
