import {Component, OnInit} from '@angular/core';
import {NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {FormControl, FormGroup, Validators} from '@ng-stack/forms';
import {DocumentFormat, DocumentFS, DocumentLanguage} from '../text-document-form/state/document-form.model';
import {DocumentFormQuery, DocumentFormService} from '../text-document-form/state';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {LanguerValidatorsModel, validateAllFormFields} from '../../services/utils/miscellaneous';
import {ActivatedRoute, Router} from '@angular/router';
import {of} from 'rxjs';
import {delay, filter, map, switchMap, tap} from 'rxjs/operators';
import {DocumentService} from '../document/state/document.service';
import {DocumentQuery} from '../document/state/document.query';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DocumentStatus} from '../document/state/document.store';

@UntilDestroy()
@Component({
  selector: 'lng-video-document-form',
  templateUrl: './video-document-form.component.html',
  styleUrls: ['./video-document-form.component.scss']
})
export class VideoDocumentFormComponent implements OnInit {
  currentStep: number = 0;
  videoFile: File | undefined;
  subtitlesFile: File | undefined;
  form: FormGroup<DocumentFS, LanguerValidatorsModel> = new FormGroup<any, any>({
    language: new FormControl<DocumentLanguage>('ja-jpan'),
    caption: new FormControl<string>('', [Validators.required, Validators.minLength(1)]),
    tags: new FormControl<string[]>([]),
    category: new FormControl<string[]>([]),
    source: new FormControl(''),
    format: new FormControl<DocumentFormat>('video'),
    content: new FormControl(''),
  });
  availableCategories$ = this.documentFormQuery.categories.selectAll();
  availableTags$ = this.documentFormQuery.tags.selectAll();
  uploadProgress$ = this.documentFormQuery.select('uploadProgress');
  documentId: number = null;
  processingProgress: number = 0;

  constructor(private documentFormQuery: DocumentFormQuery,
              private documentFormService: DocumentFormService,
              private documentService: DocumentService,
              private documentQuery: DocumentQuery,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.documentFormService.fetchAvailableTags();
    this.documentFormService.fetchAvailableCategories();
    this.form.controls.category.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      value.length > 1 && this.form.controls.category.setValue([value[value.length - 1]] as any);
    });

    this.activatedRoute.queryParamMap.pipe(
      map(params => Number(params.get('document')) || undefined),
      tap(id => {
        this.documentId = id || null;
        if (id && this.currentStep < 2) {
          this.currentStep = 2;
        }
        if (!id) {
          this.currentStep = 0;
        }
      }),
      switchMap(id => of(id)),
      filter(id => !!id),
      switchMap(id => this.documentService.load(id)),
      switchMap(document => this.documentService.updateProgress(document.id)),
      tap(progress => this.processingProgress = Math.round(progress.progress * 100.0)),
      filter(progress => progress.state !== DocumentStatus.PROCESSING),
      switchMap(progress => this.documentService.load(progress.id)),
      delay(500),
      untilDestroyed(this)
    ).subscribe(progress => this.router.navigate(['/documents', (progress as any).id]));
  }

  handleUpload({file, fileList}: NzUploadChangeParam) {
    console.log(file, fileList);
  }

  nextStep() {
    if (this.currentStep === 0) {
      if (!(this.subtitlesFile && this.videoFile)) {
        this.form.setErrors({__general__: ['You need to select files to upload']});
        return;
      }
    }
    ++this.currentStep;
  }

  prevStep() {
    --this.currentStep;
  }

  upload() {
    validateAllFormFields(this.form);
    if (this.form.invalid) {
      return;
    }

    this.documentFormService.createVideo(
      {...this.form.value, category: this.form.value.category[0] || null},
      {video: this.videoFile, subtitles: this.subtitlesFile})
      .pipe(delay(500))
      .subscribe(document => this.router.navigate([], {queryParams: {document: document.id}}));
    this.nextStep();
  }

  onVideoSelected($event: Event | NgxFileDropEntry[]) {
    this._handleFileSelection($event).then(file => {
      this.videoFile = file;
      this.form.controls.caption.setValue(file.name);
    });
  }

  onSubtitleSelected($event: Event | NgxFileDropEntry[]) {
    this._handleFileSelection($event).then(file => this.subtitlesFile = file);
  }

  private async _handleFileSelection($event: Event | NgxFileDropEntry[]): Promise<File> {
    return new Promise((resolve, reject) => {
      if (Array.isArray($event)) {
        ($event[0].fileEntry as FileSystemFileEntry).file(file => {
          resolve(file);
        });
      } else if ($event instanceof Event && $event.target instanceof HTMLInputElement) {
        resolve($event.target.files[0]);
      } else {
        reject(new Error('$event is not of type (Event | NgxFileDropEntry[]) or $event.target is not of type HTMLInputElement'));
      }
    });
  }
}
