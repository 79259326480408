import { Component, OnInit } from '@angular/core';
import {NotificationComponent} from '../../../../modules/notification/components/notification.component';
import {DuplicateWordNotification} from '../state/word-list.model';
import {NotificationService} from '../../../../modules/notification/state/notification.service';
import {WordListService} from '../state/word-list.service';
import {Router} from '@angular/router';

@Component({
  selector: 'lng-duplicate-word-notification',
  templateUrl: './duplicate-word-notification.component.html',
  styleUrls: ['./duplicate-word-notification.component.scss']
})
export class DuplicateWordNotificationComponent extends NotificationComponent<DuplicateWordNotification> {
  constructor(private notificationService: NotificationService,
              private wordListService: WordListService,
              private router: Router) {
    super();
  }

  navigateToDocument(documentId: number) {
    this.notificationService.remove(this.notification.id);
    this.router.navigate(['/documents', documentId]);
  }

  undo() {
    this.wordListService.removeWord(this.notification.word);
    this.notificationService.remove(this.notification.id);
  }
}
