<ng-container *ngIf="(importedDocumentStubs$ | async).length > 0 && (currentCategory$ | async) === null">
  <h2 nz-typography class="document-list-header" i18n>Imported documents to configure</h2>

  <div class="document-list">
    <nz-card *ngFor="let document of (importedDocumentStubs$ | async)"
             [nzHoverable]="true"
             (click)="gotoDocumentStub(document)"
             [nzTitle]="document.caption || '<No title>'"
             [nzActions]="[actionDeleteStub]">
      <div class="document">
        <div style="margin-top: -15px;">
        </div>
      </div>
      <ng-template #actionDeleteStub>
        <i (click)="deleteDocumentStub(document, $event)" nz-icon nzType="delete"></i>
      </ng-template>
    </nz-card>
  </div>
</ng-container>

<h2 nz-typography class="document-list-header" i18n>Documents</h2>

<div class="document-list">
  <ng-container *ngIf="isLoading$ | async; else documentList">
    <nz-card *ngFor="let document of [1, 2, 3, 4, 5, 6]"
             [nzHoverable]="false">
      <div class="document-category">
        <nz-skeleton [nzParagraph]="{rows: 6}" [nzActive]="true" [nzTitle]="true"></nz-skeleton>
      </div>
    </nz-card>
  </ng-container>
  <ng-template #documentList>
    <ng-container *ngIf="(currentCategory$ | async) === null; else goUp">
      <nz-card *ngFor="let category of (categories$ | async)"
               [routerLink]="['/library', category]"
               class="category"
               [nzHoverable]="true">
        <div class="document-category">
          <div class="category-name">{{category}}</div>
        </div>
      </nz-card>
    </ng-container>

    <ng-template #goUp>
      <nz-card [routerLink]="['/library']"
               [nzHoverable]="true">
        <div class="document-category category-up">
          <div class="category-name">
            <i nz-icon nzType="arrow-left"></i>
          </div>
        </div>
      </nz-card>
    </ng-template>

    <nz-card *ngFor="let document of (documents$ | async)"
             [nzHoverable]="true"
             (click)="gotoDocument(document)"
             [nzTitle]="document.caption || '<No title>'" [nzActions]="document.stub ? [] : [actionEdit, actionDelete]">
      <div class="document">
        <div style="margin-top: -15px;">
          <p *ngIf="document.category">
            <nz-tag nzColor="blue">{{document.category}}</nz-tag>
          </p>
          <p *ngIf="document.tags.length > 0">
            <nz-tag *ngFor="let tag of document.tags" nzColor="success">{{tag}}</nz-tag>
          </p>
        </div>
      </div>
      <ng-template #actionEdit>
        <i (click)="editDocument(document, $event)" nz-icon nzType="edit"></i>
      </ng-template>

      <ng-template #actionDelete>
        <i (click)="deleteDocument(document, $event)" nz-icon nzType="delete"></i>
      </ng-template>
    </nz-card>
  </ng-template>
</div>
