<div role="alert"
     class="warning notification-container">
  <span i18n>The word <b>({{notification.word}})</b> has also been added to the following documents:</span>
  <ul class="document-links">
    <li *ngFor="let document of notification.documents">
      <a href="javascript:undefined" (click)="navigateToDocument(document.id)">{{document.caption}}</a>
    </li>
  </ul>
  <div class="notification-action">
    <button (click)="undo()" mat-button i18n>Undo</button>
  </div>
</div>
