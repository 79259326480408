import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface WordDetailsState {}

export function createInitialState(): WordDetailsState {
  return {
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'WordDetails' })
export class WordDetailsStore extends Store<WordDetailsState> {

  constructor() {
    super(createInitialState());
  }

}

