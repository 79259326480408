import {EntityStore, Store, StoreConfig, transaction} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {createFormInitialState, DocumentFormState, TagState, TagStoreState} from './document-form.model';

export class AvailableTagsStore extends EntityStore<TagStoreState, TagState> {
  @transaction()
  clear() {
    this.setLoading(true);
    this.set([]);
  }
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'DocumentForm' })
export class DocumentFormStore extends Store<DocumentFormState> {
  private readonly _tags: AvailableTagsStore;
  private readonly _categories: AvailableTagsStore;

  get tags(): AvailableTagsStore {
    return this._tags;
  }

  get categories(): AvailableTagsStore {
    return this._categories;
  }

  constructor() {
    super(createFormInitialState());
    this._tags = new AvailableTagsStore({}, {idKey: 'tag', name: 'DocumentForm/tags'});
    this._categories = new AvailableTagsStore({}, {idKey: 'tag', name: 'DocumentForm/categories'});
  }
}

