import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CharacterDetailsComponent} from './character-details.component';
import {PipesModule} from '../../../modules/pipes/pipes.module';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [
    CharacterDetailsComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    NzButtonModule,
    NzIconModule
  ],
  exports: [
    CharacterDetailsComponent
  ]
})
export class CharacterDetailsModule {
}
