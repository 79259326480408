import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@ng-stack/forms';
import {HashMap, PersistNgFormPlugin} from '@datorama/akita';
import {WordListQuery} from '../word-list/state/word-list.query';
import {ProperNoun, ProperNounType} from '../word-list/state/word-list.model';
import {distinctUntilChanged, filter, finalize} from 'rxjs/operators';
import {WordListService} from '../word-list/state/word-list.service';
import {CharacterService} from './state/character/character.service';
import {CharacterQuery} from './state/character/character.query';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';


export interface WordDetailsFormState {
  properNoun: boolean;
  machineTranslated: boolean;
  properNounType: ProperNounType;
  reading: string;
  writing: string;
  meaningShow: string;
  meaning: string;
  partsOfSpeach: string;
  hint: string;
}

@UntilDestroy()
@Component({
  selector: 'lng-word-details',
  templateUrl: './word-details.component.html',
  styleUrls: ['./word-details.component.scss']
})
export class WordDetailsComponent implements OnInit, OnDestroy {
  form: FormGroup<WordDetailsFormState>;
  characters: string[] = []; //['内', '容'];
  selectedChars: HashMap<boolean> = {}; // {'内': true, '容': false};
  language: string;
  loadingChars: HashMap<boolean> = {};
  public ProperNoun = ProperNoun;
  private persistForm: PersistNgFormPlugin;
  loading$ = this.wordListQuery.selectLoading();

  constructor(private wordListQuery: WordListQuery, private wordListService: WordListService,
              private characterService: CharacterService, private characterQuery: CharacterQuery) {
    this.form = new FormGroup<WordDetailsFormState>({
      properNoun: new FormControl<boolean>(false),
      machineTranslated: new FormControl<boolean>(false),
      properNounType: new FormControl<ProperNounType>(null),
      reading: new FormControl<string>(''),
      writing: new FormControl<string>(''),
      meaningShow: new FormControl<string>(''),
      meaning: new FormControl<string>(''),
      partsOfSpeach: new FormControl<string>(''),
      hint: new FormControl<string>(''),
    });

    this.persistForm = new PersistNgFormPlugin(this.wordListQuery, () => ({}), {formKey: 'wordDetailsForm'}).setForm(this.form);
  }

  ngOnInit() {
    this.form.controls.properNoun.valueChanges.pipe(
      filter(val => val),
      filter(val => this.form.controls.properNounType.value == null),
      untilDestroyed(this)).subscribe(val => this.form.controls.properNounType.setValue(ProperNoun.PROPER_NOUN_OTHER));

    this.form.get('properNoun').valueChanges.pipe(untilDestroyed(this), filter(value => !value))
      .subscribe(value => this.form.get('properNounType').setValue(null));

    this.characterQuery.selectLoadingCharacters().pipe(untilDestroyed(this)).subscribe(chars => this.loadingChars = chars);

    this.wordListQuery.selectEdited().pipe(untilDestroyed(this), filter(word => word != null)).subscribe(word => {
      this.characters = word.availableCharacters;
      this.selectedChars = {};
      this.characters.forEach(char => this.selectedChars[char] = word.characters.includes(char));
      this.form.patchValue({...word, partsOfSpeach: word.partsOfSpeach.join('\n'), meaning: word.meaning.join('\n')});
    });

    // this.wordListQuery.select(state => state.akitaForm).pipe(
    //   filter(f => f != null && Object.entries(f).length !== 0),
    //   distinctUntilChanged((a, b) => a.reading === b.reading && a.writing === b.writing && a.meaning === b.meaning),
    //   filter(v => !(v.reading === this.form.value.reading && v.writing === this.form.value.writing && v.meaning === this.form.value.meaning.replace(/\n/g, ', '))),
    //   untilDestroyed(this)
    // ).subscribe(value => this.form.patchValue({
    //   ...value,
    //   meaning: value.meaning.replace(/, /g, '\n')
    // }, {emitEvent: false}));

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.wordListService.updateForm(value));
  }

  ngOnDestroy(): void {
    this.persistForm.destroy();
  }

  toggleCharacter(char: string) {
    if (this.selectedChars[char]) {
      this.characterService.setActive(char);
      // this.characterService.remove(char);
    } else {
      this.characterService.add$(char, this.wordListQuery.getEdited().language).subscribe();
    }
    // this.wordListService.addCharacter(char);
    // this.selectedChars[char] = !this.selectedChars[char];
  }

  updateWord() {
    const value = this.form.value;
    this.form.disable();
    this.wordListService.acceptEditWord(value).pipe(finalize(() => this.form.enable())).subscribe();
  }

  dismiss() {
    this.wordListService.editWord(null);
  }
}
