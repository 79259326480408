import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {setLoading} from '@datorama/akita';
import {catchError, exhaustMap, filter, switchMap, take, tap} from 'rxjs/operators';
import {EbookWordStore} from './ebook-word.store';
import {RouterQuery} from '@datorama/akita-ng-router-store';
import {interval, Observable, of, throwError} from 'rxjs';
import {EbookWordQuery} from './ebook-word.query';
import {setError} from '../../../../utils/rxjs/akita';
import {Router} from '@angular/router';
import {logIt} from '../../../../utils/rxjs/operators';
import {Ebook, EbookWord, EbookWordStatus} from './ebook-word.model';
import {DocumentListStore} from '../../../document-list/state/document/document-list.store';
import {CharacterService} from '../../../document/word-details/state/character/character.service';
import {NotificationService} from '../../../../modules/notification/state/notification.service';
import {DocumentStub} from '../../../document-list/state/document/document-list.model';
import {CharacterQuery} from '../../../document/word-details/state/character/character.query';
import {Word} from '../../../document/word-list/state/word-list.model';
import {WordListService} from '../../../document/word-list/state/word-list.service';
import {DocumentListService} from '../../../document-list/state/document/document-list.service';
import {DocumentStubService} from '../../../document-list/state/document-stub/document-stub.service';

@Injectable({providedIn: 'root'})
export class EbookWordService {

  constructor(private store: EbookWordStore,
              private query: EbookWordQuery,
              private routerQuery: RouterQuery,
              private router: Router,
              private characterQuery: CharacterQuery,
              private notificationService: NotificationService,
              private characterService: CharacterService,
              private wordListService: WordListService,
              private documentListService: DocumentListService,
              private documentStubService: DocumentStubService,
              private http: HttpClient) {
  }


  public connectQueryToDocument() {
    return this.routerQuery.selectQueryParams('id')
      .pipe(
        logIt('connectQueryToDocument ID changed'),
        tap(() => this.store.setError(null)),
        setLoading(this.store),
        switchMap(id => {
          if (id == null) {
            this.store.update({step: 0});
            return of(null);
          } else {
            this.store.update({step: 2});
            return this.loadDocument(id).pipe(
              switchMap((document: Ebook) => {
                if (document != null && document.progress < 1.0) {
                  return interval(1500).pipe(
                    exhaustMap(() => this.http.get<{ progress: number, error: string | null }>(`/api/kindle/import/${document.id}/progress/`)),
                    tap(data => this.store.update(state => ({
                      ...state,
                      document: {...state.document, error: data.error, progress: data.progress}
                    }))),
                    catchError(error => {
                      return of({error: null, progress: 0.0});
                    }),
                    switchMap((data) => (data.error || data.progress === 1.0) ? throwError(data) : of(null))
                  );
                }
                return this.loadWords();
              }),
              catchError(error => {
                if (error.progress === 1.0) {
                  return this.loadWords()
                } else if (error._original.status === 404) {
                  this.router.navigate([], {queryParams: {}});
                }
                return of(null)
              })
            );
          }
        }),
        tap(() => this.store.setError(null)),
        catchError(error => {
          this.store.setError(error);
          return of(null);
        }),
      );
  }

  public deleteDocument() {
    const documentId = this.query.getValue().document.id
    return this.http.delete(`/api/kindle/import/${documentId}/`).pipe(
      setLoading(this.store),
      tap(() => this.store.reset()),
      tap(() => this.documentStubService.remove(documentId)),
      setError(this.store),
      switchMap(() => this.router.navigate([], {queryParams: {}}))
    );
  }

  public reloadDocument() {
    return this.routerQuery.selectQueryParams('id')
      .pipe(
        take(1),
        setLoading(this.store),
        filter(id => id != null),
        switchMap(id => this.loadDocument(id)),
        setError(this.store),
      );
  }

  public reset() {
    this.store.reset();
  }

  public acceptWord(wordId: number) {
    const documentId = this.query.getValue().document?.id;
    const originalStatus = this.query.getEntity(wordId).status;
    this.store.update(wordId, {status: EbookWordStatus.Accepted})
    return this.http.put(`/api/kindle/import/${documentId}/accept/${wordId}/`, {})
      .pipe(
        catchError((error) => {
          this.store.update(wordId, {status: originalStatus})
          return throwError(error)
        }),
        this.notificationService.catchErrorOperator()
      )
  }

  public rejectWord(wordId: number) {
    const documentId = this.query.getValue().document?.id;
    const originalStatus = this.query.getEntity(wordId).status;
    this.store.update(wordId, {status: EbookWordStatus.Rejected})
    return this.http.delete(`/api/kindle/import/${documentId}/reject/${wordId}/`, {})
      .pipe(
        catchError((error) => {
          this.store.update(wordId, {status: originalStatus})
          return throwError(error)
        }),
        this.notificationService.catchErrorOperator()
      )
  }

  public finalizeDocument() {
    const documentId = this.query.getValue().document?.id;
    return this.http.post<DocumentStub>(`/api/kindle/import/${documentId}/finalize/`, {})
      .pipe(
        setLoading(this.store),
        tap(document => {
          this.documentStubService.remove(documentId);
          this.documentListService.add(document);
          this.router.navigate(['/documents', document.id]);
        }),
        this.notificationService.catchErrorOperator()
      )
  }

  private loadDocument(id: number): Observable<Ebook> {
    return this.query.getValue().document?.id === id
      ? of(this.query.getValue().document)
      : this.http.get<Ebook>(`/api/kindle/import/${id}/`)
        .pipe(
          tap(document => this.store.update({document})),
          tap(document => this.characterService.set(document.characters))
        );
  }

  private loadWords() {
    this.store.set([]);
    return this.http.get<{ebookWord: EbookWord, word: Word}[]>(`/api/kindle/import/${this.query.getValue().document.id}/words/`).pipe(
      setLoading(this.store),
      tap(entities => {
        this.store.set(entities.map(word => ({...word.ebookWord, documentCaptions: word.word.documents.map(doc => doc.caption)})));
        this.wordListService.set(entities.map(word => word.word));
      })
    )
  }
}
