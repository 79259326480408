function walk (obj, converter: (match: string) => string) {
  if (!obj || typeof obj !== 'object') return obj;
  if (isDate(obj) || isRegex(obj)) return obj;
  if (isArray(obj)) return map(obj, (obj) => walk(obj, converter));
  return reduce(objectKeys(obj), (acc, key) => {
    let camel = converter(key);
    acc[camel] = walk(obj[key], converter);
    return acc;
  }, {});
}

function camelCase(str: string) {
  return str.replace(/[_.-](\w|$)/g, function (_,x) {
    return x.toUpperCase();
  });
}

export function unCamelCase(text: string, separator: string = '_') {
  // Replace all capital letters and group of numbers by the
  // separator followed by lowercase version of the match
  text = text.replace(/[A-Z]|\d+/g, match => separator + match.toLowerCase());

  // Remove first separator (to avoid _hello_world name)
  return text.replace(new RegExp('^' + separator), '');
}

const isArray = Array.isArray || function (obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
};

const isDate = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Date]';
};

const isRegex = function (obj) {
  return Object.prototype.toString.call(obj) === '[object RegExp]';
};

const has = Object.prototype.hasOwnProperty;
const objectKeys = Object.keys || function (obj) {
  const keys = [];
  for (var key in obj) {
    if (has.call(obj, key)) keys.push(key);
  }
  return keys;
};

function map (xs, f) {
  if (xs.map) return xs.map(f);
  const res = [];
  for (let i = 0; i < xs.length; i++) {
    res.push(f(xs[i], i));
  }
  return res;
}

function reduce (xs, f, acc) {
  if (xs.reduce) return xs.reduce(f, acc);
  for (var i = 0; i < xs.length; i++) {
    acc = f(acc, xs[i], i);
  }
  return acc;
}

export function camelize(obj: any): any {
  if (typeof obj === 'string') return camelCase(obj);
  return walk(obj, camelCase);
}

export function uncamelize(obj: any): any {
  if (typeof obj === 'string') return unCamelCase(obj);
  return walk(obj, unCamelCase);
}
