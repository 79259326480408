/* tslint:disable:member-ordering */
import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {ID} from '@datorama/akita';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentListService} from './state/document/document-list.service';
import {DocumentListQuery} from './state/document/document-list.query';
import {DocumentStub} from './state/document/document-list.model';
import {DocumentService} from '../document/state/document.service';
import {DocumentStatus} from '../document/state/document.store';
import {RoutableComponent} from '../../services/utils/miscellaneous';
import {DocumentFormService} from '../text-document-form/state';
import {ImportedDocumentStub} from './state/document-stub/document-stub.model';
import {DocumentStubQuery} from './state/document-stub/document-stub.query';
import {DocumentStubService} from './state/document-stub/document-stub.service';
import {mapAnyTrue} from '../../utils/rxjs/operators';

@Component({
  selector: 'lng-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent extends RoutableComponent implements OnInit {
  public DocumentStatus = DocumentStatus;
  public activeDocument$: Observable<ID>;
  public documents$: Observable<DocumentStub[]>;
  public importedDocumentStubs$: Observable<ImportedDocumentStub[]>;
  public categories$: Observable<string[]>;
  public currentCategory$: Observable<string>;
  public isLoading$: Observable<boolean>;

  constructor(private query: DocumentListQuery,
              private service: DocumentListService,
              private documentStubService: DocumentStubService,
              private documentStubQuery: DocumentStubQuery,
              private documentService: DocumentService,
              private documentFormService: DocumentFormService,
              router: Router,
              activatedRoute: ActivatedRoute) {
    super(activatedRoute, router);
  }

  ngOnInit() {
    this.extractParams('category').subscribe(category => this.service.setActiveCategory(category));
    this.isLoading$ = combineLatest(this.query.selectLoading(), this.documentStubQuery.selectLoading()).pipe(mapAnyTrue());
    this.documentService.clear();
    this.documents$ = this.query.selectDocumentsInCurrentCategory();
    this.importedDocumentStubs$ = this.documentStubQuery.selectAll();
    this.activeDocument$ = this.query.selectActiveId();
    this.categories$ = this.query.selectCategories();
    this.currentCategory$ = this.query.selectActiveCategory();
    this.service.loadDocuments$().subscribe();
    this.documentStubService.loadDocuments$().subscribe();
  }

  gotoDocument(document: DocumentStub) {
    this.service.setActive(document.id);
    this.router.navigate(['/documents', document.id]);
  }

  gotoDocumentStub(document: ImportedDocumentStub) {
    this.router.navigate(['/kindle/import'], {queryParams: {id: document.id}});
  }

  deleteDocument(document: DocumentStub, $event: MouseEvent) {
    $event.stopPropagation();
    this.documentService.deleteDocument(document.id);
  }

  deleteDocumentStub(document: ImportedDocumentStub, $event: MouseEvent) {
    $event.stopPropagation();
    this.documentStubService.deleteDocument(document.id);
  }

  editDocument(document: DocumentStub, $event: MouseEvent) {
    $event.stopPropagation();
    if (!document.stub) {
      this.documentService.showEditModal(document);
    }
  }
}
