import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {IsNotLoggedIn} from '../../services/auth-guard/auth-guard.service';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzButtonModule} from 'ng-zorro-antd/button';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([
      {path: 'login', component: LoginComponent, canActivate: [IsNotLoggedIn]},
    ]),
    NzFormModule,
    NzInputModule,
    NzButtonModule
  ]
})
export class LoginModule {
}
