import { Pipe, PipeTransform } from '@angular/core';
import {EbookWord, EbookWordExtended} from '../state/word/ebook-word.model';
import {Character} from '../../document/word-details/state/character/character.model';

export interface HlCharacter {
    present: boolean;
    literal: string;
    isCharacter: boolean;
}

@Pipe({
  name: 'word2Characters'
})
export class Word2CharactersPipe implements PipeTransform {
  transform(word: EbookWordExtended, _usedCharacters: (string|Character)[]): HlCharacter[] {
    const usedCharacters: string[] = _usedCharacters.map(char => typeof char === 'string' ? char : char.literal);

    return word.writing.split('')
      .map(w => ({literal: w, present: usedCharacters.includes(w), isCharacter: word.availableCharacters.includes(w)}))
  }
}
