import {Inject, Injectable, Type} from '@angular/core';
import {HashMap} from '@datorama/akita';
import {NotificationComponent} from '../components/notification.component';
import {Notification} from '../state/notification.model';
import {DefaultNotificationClazz, NotificationClazz} from '../notification.tokens';

export type NotificationClazzMapping = HashMap<Type<NotificationComponent<Notification>>>

@Injectable({
  providedIn: 'root'
})
export class NotificationMapperService {
  get mapping(): HashMap<Type<any>> { return this._mapping; }

  private _mapping: NotificationClazzMapping = {};

  constructor(@Inject(NotificationClazz) private notificationComponents: {name: string, component: Type<NotificationComponent<any>>}[],
              @Inject(DefaultNotificationClazz) private DEFAULT_COMPONENT: Type<NotificationComponent<any>>) {
    this.notificationComponents.forEach(componentDef => this._mapping[componentDef.name] = componentDef.component)
  }

  clazzToComponent(clazz: string): Type<NotificationComponent<Notification>> {
    return this.mapping[clazz] || this.DEFAULT_COMPONENT;
  }
}
