import { Injectable } from '@angular/core';
import {ID, Store} from '@datorama/akita';
import { NotificationStore } from './notification.store';
import {createGeneralNotification, Notification} from './notification.model';
import {catchError, delay, tap} from 'rxjs/operators';
import {NotificationQuery} from './notification.query';
import {Observable, of, throwError} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private notificationStore: NotificationStore,
              private notificationQuery: NotificationQuery) {
  }

  add(notification: Notification) {
    this.notificationStore.add(notification);
    if (notification.duration > 0) {
      of(notification.id).pipe(delay(notification.duration)).subscribe(id => {
        if (this.notificationQuery.hasEntity(id)) {
          this.remove(id);
        }
      });
    }
  }

  remove(id: ID) {
    this.notificationStore.remove(id);
  }

  addFromError(error: any, message: string | null = null): Observable<any> {
    if (!message) {
      message = error?.__general__ ? (error?.__general__[0] || '') : error.toString()
    }

    this.add(createGeneralNotification({content: message}))
    return throwError(error);
  }

  /**
   * returns operator which can be chained as part of rxjs chain
   */
  catchErrorOperator<T>(message?: string, rethrow: boolean = true, store?: Store): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => {
      return source.pipe(
        catchError(error => {
          this.addFromError(error, message);
          return rethrow ? throwError(error) : of(null)
        })
      );
    }
  }
}

