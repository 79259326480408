import {QueryEntity} from '@datorama/akita';
import {DocumentListState, DocumentListStore} from './document-list.store';
import {DocumentStub} from './document-list.model';
import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DocumentListQuery extends QueryEntity<DocumentListState, DocumentStub, number> {
  constructor(protected store: DocumentListStore) {
    super(store);
  }

  selectCategories(): Observable<string[]> {
    return this.select(state => state.categories);
  }

  selectDocumentsInCurrentCategory() {
    return combineLatest(this.selectActiveCategory(), this.selectAll())
      .pipe(map(([cat, docs]) => docs.filter(doc => doc.category === cat)));
  }

  selectActiveCategory() {
    return this.select(state => state.activeCategory);
  }
}
