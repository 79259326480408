import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KindleComponent} from './kindle.component';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {RouterModule} from '@angular/router';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {IsLoggedIn} from '../../services/auth-guard/auth-guard.service';
import {NgxFileDropModule} from 'ngx-file-drop';
import {NgxFilesizeModule} from 'ngx-filesize';
import {NzGridModule} from 'ng-zorro-antd/grid';
import { EbookCardComponent } from './ebook-card/ebook-card.component';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzResultModule} from 'ng-zorro-antd/result';
import { WordSelectionComponent } from './word-selection/word-selection.component';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {PipesModule} from '../../modules/pipes/pipes.module';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NgStackFormsModule} from '@ng-stack/forms';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import { HLOccurrencesPipe } from './word-selection/hloccurrences.pipe';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import { Word2CharactersPipe } from './word-selection/word2-characters.pipe';


@NgModule({
  declarations: [
    KindleComponent,
    EbookCardComponent,
    WordSelectionComponent,
    HLOccurrencesPipe,
    Word2CharactersPipe
  ],
  imports: [
    CommonModule,
    NzStepsModule,
    NzUploadModule,
    NzIconModule,
    RouterModule.forChild([
      {path: 'kindle/import', component: KindleComponent, canActivate: [IsLoggedIn]}
    ]),
    NgxFileDropModule,
    NgxFilesizeModule,
    NzGridModule,
    NzCardModule,
    NzTagModule,
    NzButtonModule,
    NzProgressModule,
    NzTypographyModule,
    NzResultModule,
    NzSkeletonModule,
    NzLayoutModule,
    PipesModule,
    NzRadioModule,
    NgStackFormsModule,
    NzDividerModule,
    NzTableModule,
    NzToolTipModule,
    NzPopoverModule
  ],
  exports: [
    KindleComponent
  ]
})
export class KindleModule {
}
