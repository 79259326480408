import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditorComponent} from './editor.component';
import {EditorQuery, EditorService, EditorStore} from './state';
import {ModelModule} from '../../../model/model.module';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    EditorComponent,
  ],
  imports: [
    CommonModule,
    ModelModule,
    NzSkeletonModule,
    NzLayoutModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    NzTagModule,
    RouterModule
  ],
  exports: [
    EditorComponent,
  ],
  providers: [
    EditorQuery,
    EditorService,
    EditorStore
  ]
})
export class EditorModule {
}
