import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ImportedDocumentStub } from './document-stub.model';

export interface DocumentStubState extends EntityState<ImportedDocumentStub> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ImportedDocumentStub' })
export class DocumentStubStore extends EntityStore<DocumentStubState> {

  constructor() {
    super();
  }

}
