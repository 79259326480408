import {DocumentListStore} from './document-list.store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DocumentStub} from './document-list.model';
import {filter, finalize, map, tap} from 'rxjs/operators';
import {DocumentListQuery} from './document-list.query';
import {WordListService} from '../../../document/word-list/state/word-list.service';
import {forkJoin, Observable} from 'rxjs';
import {setLoading} from '@datorama/akita';

@Injectable({providedIn: 'root'})
export class DocumentListService {
  constructor(private documentListStore: DocumentListStore,
              private documentListQuery: DocumentListQuery,
              private wordListService: WordListService,
              private http: HttpClient) {
  }

  loadDocuments$(force: boolean = false): Observable<DocumentStub[]> {
    return this.http.get<DocumentStub[]>('/api/documents/').pipe(
      setLoading(this.documentListStore),
      tap(data => this.documentListStore.set(data)),
    )
  }

  clearActive() {
    this.documentListStore.setActive(null);
  }

  setActive(id: number) {
    if (!this.documentListQuery.hasEntity(id)) {
      this.loadDocuments$(true);
      this.documentListQuery.selectLoading().pipe(filter(loading => !loading))
        .subscribe(() => this.documentListStore.setActive(id));
    } else {
      this.documentListStore.setActive(id);
    }
  }

  setActiveCategory(category: string | undefined) {
    this.documentListStore.setActiveCategory(category || null);
  }

  add(document: DocumentStub) {
    this.documentListStore.add(document);
  }
}
