<div class="form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" nz-form nzLayout="vertical">
    <nz-form-item>
      <nz-form-control [nzErrorTip]="form.controls.caption.errors | concatErrors">
        <input nzSize="large" nz-input placeholder="Title" i18n-placeholder formControlName="caption">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <textarea nz-input
                nzSize="small"
                (paste)="onContentPaste($event)"
                class="textarea-control"
                [nzAutosize]="{minRows: 3, maxRows: 12}"
                placeholder="Content"
                formControlName="content">
      </textarea>
    </nz-form-item>

    <nz-form-item>
      <nz-select nzMode="tags" nzMaxTagCount="1" formControlName="category" nzAllowClear nzPlaceHolder="Choose category"
                 i18n-nzPlaceHolder>
        <nz-option *ngFor="let category of (availableCategories$ | async)" [nzValue]="category.tag"
                   [nzLabel]="category.tag"></nz-option>
      </nz-select>
    </nz-form-item>
    <nz-form-item>
      <nz-select nzMode="tags" formControlName="tags" nzPlaceHolder="Choose tags" i18n-nzPlaceHolder>
        <nz-option *ngFor="let tag of (availableTags$ | async)" [nzLabel]="tag.tag" [nzValue]="tag.tag"></nz-option>
      </nz-select>
    </nz-form-item>

    <lng-language-select formControlName="language"></lng-language-select>

    <div class="controls">
      <a routerLink="/library" [hidden]="loading$ | async">
        <i nz-icon nzType="left"></i>
        <ng-container i18n>Back</ng-container>
      </a>
      <button [disabled]="loading$ | async"
              [nzLoading]="loading$ | async"
              class="accept-button"
              type="submit" nz-button nzType="primary" i18n>Create
      </button>
    </div>
  </form>
</div>
