import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Character} from '../word-details/state/character/character.model';
import {Observable} from 'rxjs';
import {CharacterQuery} from '../word-details/state/character/character.query';
import {CharacterService} from '../word-details/state/character/character.service';

@Component({
  selector: 'lng-character-details',
  templateUrl: './character-details.component.html',
  styleUrls: ['./character-details.component.scss']
})
export class CharacterDetailsComponent implements OnInit {
  @Input() character: Character;
  @Input() language: string;

  constructor(private characterService: CharacterService) { }

  ngOnInit() {}

  back() {
    this.characterService.setActive(null);
  }

  remove() {
    this.characterService.remove$(this.character.literal, this.language).subscribe();
  }
}
