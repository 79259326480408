import {ModuleWithProviders, NgModule} from '@angular/core';
import {ModalOutletComponent} from './components/modal-outlet/modal-outlet.component';
import {DynamicModalComponent} from './components/dynamic-modal/dynamic-modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {GenericModalComponent} from './components/generic-modal/generic-modal.component';
import {HMR, Production} from './modal.tokens';
import {DummyModalComponent} from './components/dummy-modal/dummy-modal.component';
import {AlertModalComponent} from './components/alert-modal/alert-modal.component';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {makeModalProvider} from './modal.providers';
import {DUMMY_MODAL_ID} from './components/dummy-modal/dummy-modal.model';
import {ALERT_MODAL_ID} from './components/alert-modal/alert-modal.model';
import {CONFIRM_MODAL_ID} from './components/confirm-modal/confirm-modal.model';

@NgModule({
  declarations: [
    DynamicModalComponent,
    ModalOutletComponent,
    DummyModalComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    GenericModalComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule
  ],
  exports: [
    ModalOutletComponent,
    GenericModalComponent,
  ],
  providers: [],
  entryComponents: [
    DummyModalComponent,
    ConfirmModalComponent,
    AlertModalComponent,
  ]
})
export class ModalModule {
  static forRoot(production: boolean = true, hmr: boolean = false): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [
        {provide: Production, useValue: production},
        {provide: HMR, useValue: hmr},
        makeModalProvider(DUMMY_MODAL_ID, DummyModalComponent),
        makeModalProvider(ALERT_MODAL_ID, AlertModalComponent),
        makeModalProvider(CONFIRM_MODAL_ID, ConfirmModalComponent),
      ]
    };
  }
}
