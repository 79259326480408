import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoDocumentFormComponent} from './video-document-form.component';
import {RouterModule} from '@angular/router';
import {IsLoggedIn} from '../../services/auth-guard/auth-guard.service';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {LanguageSelectModule} from '../../components/language-select/language-select.module';
import {NgStackFormsModule} from '@ng-stack/forms';
import {PIpesModule} from '../../pipes/pipes.module';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {ReactiveFormsModule} from '@angular/forms';
import {NzResultModule} from 'ng-zorro-antd/result';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NgxFilesizeModule} from 'ngx-filesize';
import {NgxFileDropModule} from 'ngx-file-drop';

@NgModule({
  declarations: [
    VideoDocumentFormComponent
  ],
  imports: [
    CommonModule,
    NzStepsModule,
    NzUploadModule,
    NzIconModule,
    RouterModule.forChild([
      {path: 'video/import', component: VideoDocumentFormComponent, canActivate: [IsLoggedIn]}
    ]),
    NzGridModule,
    NzTypographyModule,
    NzButtonModule,
    LanguageSelectModule,
    NgStackFormsModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    PIpesModule,
    NzSelectModule,
    NzResultModule,
    NzProgressModule,
    NgxFilesizeModule,
    NgxFileDropModule
  ]
})
export class VideoDocumentFormModule {
}
