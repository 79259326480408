import {UserStore} from './user.store';
import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, finalize, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Route, Router} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {resetStores} from '@datorama/akita';
import {WINDOW} from '../../app.constants';
import {User} from './user.model';

interface ITokenResponse {
  token: string;
}

@Injectable({providedIn: 'root'})
export class UserService {
  constructor(@Inject(WINDOW) private window: Window,
              private injector: Injector,
              private store: UserStore,
              private router: Router,
              private http: HttpClient) {
  }

  getProfile$(): Observable<User> {
    this.store.setError(null);
    this.store.setLoading(true);

    const r = this.http.get<User>('/api/profile/')
      .pipe(
        tap(user => this.store.update({email: user.email, username: user.username})),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            const user: User = {email: null, username: null}
            this.store.update({...user});
            return of(user);
          }
          return throwError(error);
        }),
        finalize(() => this.store.setLoading(false)),
        shareReplay(1)
      );
    r.subscribe();
    return r;
  }

  logIn(username: string, password: string) {
    const router: Router = this.injector.get(Router);
    this.store.setLoading(true);
    this.store.setError(null);
    this.http.post<ITokenResponse>('/api/api-token-auth', {username, password})
      .pipe(
        switchMap(() => this.getProfile$()),
        finalize(() => this.store.setLoading(false))
      )
      .subscribe(
        () => router.navigate(['/']),
        error => this.store.setError(error)
      );
  }

  logOut() {
    const router: Router = this.injector.get(Router);

    this.store.setLoading(true);
    this.store.setError(null);

    this.http.post('/api/logout/', {})
      .pipe(finalize(() => this.store.setLoading(false)))
      .subscribe(
        () => {
          resetStores();
          router.navigate(['/login']);
        }
      );
  }
}
