import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RegisterComponent} from './register.component';
import {RouterModule} from '@angular/router';
import {IsNotLoggedIn} from '../../services/auth-guard/auth-guard.service';



@NgModule({
  declarations: [
    RegisterComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {path: 'register', component: RegisterComponent, canActivate: [IsNotLoggedIn]},
    ])
  ]
})
export class RegisterModule { }
