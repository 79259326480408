import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EbookWordStore} from './ebook-word.store';
import {EbookWord, EbookWordExtended, EbookWordState} from './ebook-word.model';
import {combineLatest, Observable} from 'rxjs';
import {WordListQuery} from '../../../document/word-list/state/word-list.query';
import {map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EbookWordQuery extends QueryEntity<EbookWordState> {

  constructor(protected store: EbookWordStore, private wordListQuery: WordListQuery) {
    super(store);
  }

  selectProcessing() {
    return this.select(state => Math.round((state.document?.progress || 0.0) * 100.0));
  }

  selectProcessingError() {
    return this.select(state => state.document?.error);
  }

  selectWithWords(): Observable<EbookWordExtended[]> {
    return combineLatest(
      this.selectAll(),
      this.wordListQuery.selectAll()
    ).pipe(map(([ebookWords, words]) =>
      ebookWords.map(ebookWord => ({...ebookWord, ...this.wordListQuery.getEntity(ebookWord.wordId)})))
    )
  }
}
