<div class="login-container">
  <h1 i18n>Login</h1>
  <form class="example-form" [formGroup]="form" (ngSubmit)="onSubmit()" nz-form nzLayout="vertical">
    <ul class="error-list" *ngFor="let err of (errors$ | async)">
      <li [innerHtml]="err"></li>
    </ul>

    <nz-form-item>
      <nz-form-label nzFor="username" i18n>Username</nz-form-label>
      <nz-form-control [nzErrorTip]="form.controls.username.getError('server') ? form.controls.username.getError('server').toString() : 'Username is required'">
        <input nz-input name="username" type="text" id="username" formControlName="username">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="password" i18n>Password</nz-form-label>
      <nz-form-control [nzErrorTip]="form.controls.password.getError('server') ? form.controls.password.getError('server').toString() : 'Password is required'">
        <input nz-input name="password" type="password" id="password" formControlName="password">
      </nz-form-control>
    </nz-form-item>

    <button nz-button type="submit" nzType="primary" style="width: 100%; margin-top: 15px;"
            [disabled]="isLoading$ | async" [nzLoading]="isLoading$ | async" i18n>Login</button>
  </form>
</div>
