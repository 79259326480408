import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextDocumentFormComponent} from './text-document-form.component';
import {DocumentFormQuery, DocumentFormService, DocumentFormStore} from './state';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {IsLoggedIn} from '../../services/auth-guard/auth-guard.service';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {LanguageSelectModule} from '../../components/language-select/language-select.module';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {PipesModule} from '../../modules/pipes/pipes.module';
import {PIpesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    TextDocumentFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {path: 'text/new', component: TextDocumentFormComponent, canActivate: [IsLoggedIn]}
    ]),
    NzIconModule,
    NzButtonModule,
    NzInputModule,
    NzFormModule,
    NzBadgeModule,
    LanguageSelectModule,
    NzSelectModule,
    PipesModule,
    PIpesModule
  ],
  providers: [
    DocumentFormQuery,
    DocumentFormService,
    DocumentFormStore
  ],
  exports: [
    TextDocumentFormComponent
  ]
})
export class TextDocumentFormModule {
}
