import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hl'
})
export class HighlitePipe implements PipeTransform {

  transform(value: string, args?: string): any {
    if (!args) {
      return value;
    }
    return value.replace(args, `<span class="hl">${args}</span>`);
  }

}
