import {Component, Inject, OnInit} from '@angular/core';
import {DocumentStub} from '../state/document/document-list.model';
import {FormControl, FormGroup} from '@angular/forms';
import {MODAL_DEF} from '../../../modules/modal/modal.providers';
import {DOCUMENT_EDIT_MODAL_ID, DocumentEditModal} from './document-edit-modal.modal';
import {ModalComponent} from '../../../modules/modal/utils/modal/modal.component';
import {ModalService} from '../../../modules/modal/state/modal.service';
import {DocumentService} from '../../document/state/document.service';
import {DocumentListQuery} from '../state/document/document-list.query';
import {DocumentFormQuery, DocumentFormService} from '../../text-document-form/state';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {disableEnableForm} from '../../../services/utils/miscellaneous';
import {distinctUntilChanged, filter} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'lng-document-edit-modal',
  templateUrl: './document-edit-modal.component.html',
  styleUrls: ['./document-edit-modal.component.scss']
})
export class DocumentEditModalComponent extends ModalComponent implements OnInit {
  public form = new FormGroup({
    caption: new FormControl(''),
    category: new FormControl([]),
    tags: new FormControl([])
  });

  public categories$ = this.documentFormQuery.categories.selectAll();
  public categoriesLoading$ = this.documentFormQuery.categories.selectLoading();
  public tags$ = this.documentFormQuery.tags.selectAll();
  public tagsLoading$ = this.documentFormQuery.tags.selectLoading();
  public loading$ = this.documentListQuery.select(state => state.loadingMetadata).pipe(distinctUntilChanged());
  public document: DocumentStub;

  constructor(modalService: ModalService, @Inject(MODAL_DEF) modal: DocumentEditModal,
              private documentService: DocumentService,
              private documentFormQuery: DocumentFormQuery,
              private documentFormService: DocumentFormService,
              private documentListQuery: DocumentListQuery) {
    super(modalService, DOCUMENT_EDIT_MODAL_ID);
    this.document = modal.document;

    this.form.setValue({
      caption: this.document.caption,
      category: this.document.category ? [this.document.category] : [],
      tags: this.document.tags
    });

    if (this.document.locked) {
      this.form.disable();
    }
  }

  submit() {
    this.documentService.updateMetadata(this.document.id, {...this.form.value, category: this.form.value.category[0] || null}).subscribe(
      () => this.dismiss(),
      error => this.form.setErrors(error)
    );
  }

  ngOnInit(): void {
    this.documentFormService.fetchAvailableCategories();
    this.documentFormService.fetchAvailableTags();
    this.loading$.pipe(filter(() => !this.document.locked), untilDestroyed(this)).subscribe(loading => disableEnableForm(loading, this.form))
    this.form.controls.category.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      value.length > 1 && this.form.controls.category.setValue([value[value.length - 1]])
    });
  }
}
