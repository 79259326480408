<div style="min-height: calc(100vh - 100px)">
  <form [formGroup]="form" (ngSubmit)="updateWord()" nz-form nzLayout="vertical">
    <h3 i18n>Basic</h3>

    <label nz-checkbox formControlName="machineTranslated" i18n>Machine translated</label>
    <br/>
    <label nz-checkbox formControlName="properNoun" i18n>Proper noun</label>
    <br/>

    <nz-radio-group *ngIf="form.controls.properNoun.value"
                    formControlName="properNounType"
                    class="proper-noun-type"
                    aria-label="Proper noun type"
                    i18n-aria-label>
      <label nz-radio [nzValue]="ProperNoun.PROPER_NOUN_PLACE" i18n>Place</label>
      <label nz-radio [nzValue]="ProperNoun.PROPER_NOUN_GIVEN_NAME" i18n>Given Name</label>
      <label nz-radio [nzValue]="ProperNoun.PROPER_NOUN_FAMILY_NAME" i18n>Family Name</label>
      <label nz-radio [nzValue]="ProperNoun.PROPER_NOUN_ANY_NAME" i18n>Given or Family Name</label>
      <label nz-radio [nzValue]="ProperNoun.PROPER_NOUN_PERSON" i18n>Person</label>
      <label nz-radio [nzValue]="ProperNoun.PROPER_NOUN_OTHER" i18n>Other</label>
    </nz-radio-group>
    <br/>
    <br/>


    <nz-form-item>
      <nz-form-label nzFor="reading" i18n>Reading</nz-form-label>
      <nz-form-control>
        <input nz-input name="reading" type="text" id="reading" autocomplete="off" formControlName="reading">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="writing" i18n>Writing</nz-form-label>
      <nz-form-control>
        <input nz-input name="writing" type="text" id="writing" autocomplete="off" formControlName="writing">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="meaningShow" i18n>Meaning (shown on flash cards)</nz-form-label>
      <nz-form-control>
        <input nz-input name="meaningShow" type="text" id="meaningShow" autocomplete="off"
               formControlName="meaningShow">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="meaning" i18n>Meaning</nz-form-label>
      <nz-form-control>
        <textarea nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }" name="meaning" type="text" id="meaning"
                  autocomplete="off" formControlName="meaning"></textarea>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="partsOfSpeach" i18n>Parts of speech</nz-form-label>
      <nz-form-control>
        <textarea nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }" name="partsOfSpeach" type="text" id="partsOfSpeach"
                  autocomplete="off" formControlName="partsOfSpeach"></textarea>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="hint" i18n>Hint</nz-form-label>
      <nz-form-control>
        <input nz-input name="hint" type="text" id="hint" autocomplete="off" formControlName="hint">
      </nz-form-control>
    </nz-form-item>

    <ng-container *ngIf="characters.length > 0">
      <h3 i18n>Characters</h3>
      <div style="height: 50px;">
        <button mat-button mat-icon-button
                type="button"
                class="character-button"
                [ngClass]="{loading: loadingChars[char]}"
                [color]="selectedChars[char] === true ? 'primary' : 'accent'"
                (click)="toggleCharacter(char)"
                *ngFor="let char of characters">
          <mat-icon class="char-icon">
        <span class="loading-character">
          <mat-spinner [diameter]="24" [strokeWidth]="2"></mat-spinner>
        </span>
            <span class="character">{{char}}</span>
          </mat-icon>
        </button>
      </div>
    </ng-container>

    <div style="text-align: right">
      <button type="button" nz-button nzType="default" i18n (click)="dismiss()">Cancel</button>&nbsp;
      <button type="submit" nz-button nzType="primary" i18n [nzLoading]="loading$ | async">Save</button>
    </div>
  </form>
</div>
