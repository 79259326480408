import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WordDetailsComponent} from './word-details.component';
import {WordDetailsQuery} from './state/word-details/word-details.query';
import {WordDetailsService} from './state/word-details/word-details.service';
import {SharedModule} from '../../../shared/shared.module';
import {CharacterQuery} from './state/character/character.query';
import {CharacterService} from './state/character/character.service';
import {CharacterStore} from './state/character/character.store';
import {WordDetailsStore} from './state/word-details/word-details.store';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzLayoutModule} from 'ng-zorro-antd/layout';

@NgModule({
  declarations: [WordDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzCheckboxModule,
    NzRadioModule,
    NzLayoutModule
  ],
  exports: [
    WordDetailsComponent
  ],
  providers: [
    WordDetailsQuery,
    WordDetailsService,
    WordDetailsStore,
    CharacterQuery,
    CharacterService,
    CharacterStore
  ],
})
export class WordDetailsModule {
  static forRoot(): ModuleWithProviders<WordDetailsModule> {
    return {
      ngModule: WordDetailsModule,
      providers: [
        WordDetailsQuery,
        WordDetailsService,
      ]
    };
  }
}
