import TurndownService from 'turndown';

export function makeCustomizedTurndown(): TurndownService {
  const td = new TurndownService();
  td.addRule('a', {
    filter: ['a'],
    replacement: content => content
  });

  td.addRule('code', {
    filter: ['code'],
    replacement: content => ' `' + content + '` '
  });

  td.addRule('title', {
    filter: node => node.classList.contains('novel_subtitle'),
    replacement: content => `# ${content}`
  });

  td.addRule('ruby', {
    filter: ['ruby'],
    replacement: (content, node) => {
      let r = '';

      Array.from(node.children).forEach((n: HTMLElement) => {
        if (n.tagName === 'RP') {
          return;
        }
        if (n.tagName === 'RB') {
          r += '{' + n.textContent + '}';
          return;
        }
        if (n.tagName === 'RT') {
          r += '(' + n.textContent + ')';
          return;
        }
      });

      return r;
    }
  });

  td.addRule('p-empty-line', {
    filter: node => {
      return node.tagName === 'P' && node.textContent.length === 0 && node.children.length === 1 && node.children[0].tagName === 'BR';
    },
    replacement: () => '<br />'
  });

  return td;
}
