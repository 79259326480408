import {Store, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {EditorState} from './editor.model';

export function createInitialState(): EditorState {
  return {
    content: '<p>テストのサンプル内容<p>abc</p></p>'
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'Editor' })
export class EditorStore extends Store<EditorState> {

  constructor() {
    super(createInitialState());
  }

}

