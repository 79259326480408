import {GeneralStateStore, ViewMode} from './general-state.store';
import {Injectable} from '@angular/core';
import {fromEvent, merge, of} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class GeneralStateService {
  constructor(private generalStateStore: GeneralStateStore) {
  }

  setViewMode(mode: ViewMode) {
    this.generalStateStore.update(state => ({
      ...state, showWordList: mode !== 'mobile',
      showDocumentList: mode !== 'mobile',
      viewMode: mode
    }));
  }

  showDocumentList(show: boolean) {
    this.generalStateStore.update(state => ({...state, showDocumentList: show}));
  }

  showWordList(show: boolean) {
    this.generalStateStore.update(state => ({showWordList: show}));
  }

  toggleDocumentList() {
    this.generalStateStore.update(state => ({...state, showDocumentList: !state.showDocumentList}));
  }

  toggleWordList() {
    this.generalStateStore.update(state => ({...state, showWordList: !state.showWordList}));
  }

  setRouteId(routeId: string) {
    this.generalStateStore.update(state => ({...state, routeId}));
  }

  hookViewModeSubscription() {
    merge(of({target: window}), fromEvent(window, 'resize'))
      .pipe(map<Event, ViewMode>(event => ((event.target as Window).innerWidth < 1200) ? 'mobile' : 'desktop'),
        distinctUntilChanged()
      ).subscribe(mode => this.setViewMode(mode));
  }

  toggleCollapse() {
    this.generalStateStore.update(state => ({...state, sidebarCollapsed: !state.sidebarCollapsed}))
  }
}
