import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import {DocumentStatus} from './document.store';
import {DocumentListQuery} from '../../document-list/state/document/document-list.query';

@Injectable()
export class IsDocumentReady implements CanActivate {
  constructor(private documentListQuery: DocumentListQuery, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.documentListQuery.selectLoading().pipe(
      filter(loading => !loading),
      take(1),
      map((loading) => {
        if(next.params['id'] === 'recent') { return true; }

        const documentId = +next.params['id'];
        if (isNaN(documentId)) { this.router.navigate(['/']); return false; }

        const activeDocument = this.documentListQuery.getEntity(documentId);
        if (activeDocument == null) {return false}
        if (activeDocument.state === DocumentStatus.PROCESSING) {this.router.navigate(['/documents', documentId, 'processing'], {replaceUrl: true}); return false;}
        if (activeDocument.state === DocumentStatus.CUSTOMIZING) {
          if (activeDocument.imported) {
            this.router.navigate([`/documents/form/${documentId}`], {replaceUrl: true});
          } else {
            this.router.navigate([`/documents/${documentId}/frames`], {replaceUrl: true});
          }
          return false;
        }
        return true;
      }));
  }
}
