<nz-table #basicTable
          class="word-list-table"
          [nzScroll]="{y: tableHeight}"
          [nzVirtualItemSize]="ITEM_HEIGHT"
          [nzShowPagination]="false"
          [nzData]="words$ | async"
          [nzFrontPagination]="false"
          [nzTemplateMode]="true"
          [nzBordered]="true"
          nzTableLayout="fixed"
          [nzLoading]="loading"
          nzSize="small">
  <thead>
  <tr>
    <th nzWidth="20%" i18n>Writing</th>
    <th nzWidth="20%" i18n>Reading</th>
    <th nzWidth="60%" i18n>Meaning</th>
    <th nzWidth="15%"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let word of (words$ | async); trackBy trackByContext" nz-tooltip
      [nzTooltipTrigger]="!word.machineTranslated ? null : 'hover'"
      i18n-nzTooltipTitle nzTooltipTitle="This word was machine translated" nzTooltipPlacement="left"
      (mouseenter)="activateWord(word)"
      (mouseleave)="deactivateWord(word)">
    <td class="writing-row" [ngClass]="{active: word === selected, 'machine-translated': word.machineTranslated}"
        [nzEllipsis]="true">
      <span *ngFor="let char of characters[word.context]" [ngClass]="{'hl-character': char.hl}">{{char.literal}}</span>
    </td>
    <td [nzEllipsis]="true">{{word.reading}}</td>
    <td [nzEllipsis]="true">{{word.meaning.join(', ')}}</td>
    <td>
      <ng-container *ngIf="!disabled">
        <a (click)="edit(word)" *ngIf="word.context !== editedWord"><i nz-icon nzType="edit"></i></a>
        <nz-divider nzType="vertical"></nz-divider>
        <a (click)="removeWord(word)" *ngIf="word.context !== editedWord"><i nz-icon nzType="delete"></i></a>
      </ng-container>
    </td>
  </tr>
  </tbody>
</nz-table>
