import {Query, QueryEntity} from '@datorama/akita';
import {DocumentFormStore} from './document-form.store';
import {Injectable} from '@angular/core';
import {DocumentFormState, TagState, TagStoreState} from './document-form.model';
import {FormConnector} from '../../../utils/rxjs/akita';

export class AvailableTagQuery extends QueryEntity<TagStoreState, TagState> {
}

@Injectable({providedIn: 'root'})
export class DocumentFormQuery extends Query<DocumentFormState> {
  public readonly categories: AvailableTagQuery;
  public readonly tags: AvailableTagQuery;
  public readonly form = new FormConnector(this);

  constructor(protected store: DocumentFormStore) {
    super(store);
    this.tags = new AvailableTagQuery(store.tags);
    this.categories = new AvailableTagQuery(store.categories);
  }
}
