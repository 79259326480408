import {APP_INITIALIZER, Inject, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {PageNotFoundComponent} from './views/page-not-found/page-not-found.component';
import {RouterModule} from '@angular/router';
import {appRoutes} from './app.routes';
import {IsLoggedIn, IsNotLoggedIn} from './services/auth-guard/auth-guard.service';
import {AuthInterceptor} from './services/auth-interceptor/auth-interceptor.service';
import {WINDOW} from './app.constants';
import {SharedModule} from './shared/shared.module';
import {DocumentModule} from './views/document/document.module';
import {ModelModule} from './model/model.module';
import {LoginModule} from './views/login/login.module';
import {NavbarModule} from './components/navbar/navbar.module';
import {DocumentListModule} from './views/document-list/document-list.module';
import {TextDocumentFormModule} from './views/text-document-form/text-document-form.module';
import {akitaConfig} from '@datorama/akita';
import {UserService} from './model/user';
import {NotificationModule} from './modules/notification/notification.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {KindleModule} from './views/kindle-document-form/kindle.module';
import {ProfileModule} from './views/profile/profile.module';
import {RegisterModule} from './views/register/register.module';
import {VideoDocumentFormModule} from './views/video-document-form/video-document-form.module';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {ModalModule} from './modules/modal/modal.module';

export function windowFactory() {
  return window;
}

export function initializeApp(userService: UserService): () => Promise<any> {
  return () => userService.getProfile$().toPromise().catch(() => true);
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    RegisterModule,
    DocumentModule,
    ModelModule,
    DocumentListModule,
    TextDocumentFormModule,
    NavbarModule,
    LoginModule,
    SharedModule,
    ProfileModule,
    NotificationModule,
    KindleModule,
    NzLayoutModule,
    VideoDocumentFormModule,
    ModalModule.forRoot(environment.production, environment.hmr),
    RouterModule.forRoot(
      appRoutes, {enableTracing: false}
    ),
  ],
  providers: [
    IsLoggedIn,
    IsNotLoggedIn,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: WINDOW, useFactory: windowFactory},
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [UserService], multi: true},
    {provide: NZ_I18N, useValue: en_US}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(LOCALE_ID) locale: string) {
    akitaConfig({
      resettable: true
    });
  }
}
