import {createNotification, Notification} from '../../../../modules/notification/state/notification.model';
import {DuplicateWordNotificationComponent} from '../duplicate-word-notification/duplicate-word-notification.component';

export enum ProperNoun {
  PROPER_NOUN_PERSON=0,
  PROPER_NOUN_GIVEN_NAME=1,
  PROPER_NOUN_FAMILY_NAME=2,
  PROPER_NOUN_PLACE=3,
  PROPER_NOUN_OTHER=4,
  PROPER_NOUN_ANY_NAME=5,
}

export type ProperNounType = ProperNoun|null;

export interface DocumentReduced {
id: number; caption: string;
}

export interface Word {
  context: string;
  stem:  string;
  writing: string;
  meaning: string[];
  reading: string;
  meaningShow: string;
  properNounType: ProperNounType;
  partsOfSpeach: string[];
  language: string;
  hint: string;
  occurrences: string[],
  documents: DocumentReduced[];
  machineTranslated: boolean;
  characters: string[];
  availableCharacters: string[];
}

export function createWord(params: Partial<Word>) {
  return {
    writing: '',
    reading: '',
    meaning: '',
    ...params
  } as Word;
}

export interface DuplicateWordNotification extends Notification {
  word: string;
  documentId: number,
  documents: DocumentReduced[];
}

export function createDuplicateWordNotification(params: Partial<DuplicateWordNotification>&{word: string, documentId: number, documents: DocumentReduced[]}): DuplicateWordNotification {
  return {
    ...createNotification(params),
    ...params,
    clazz: 'DuplicateWordNotificationComponent'
  };
}

