import {Subject} from 'rxjs';
import {Word} from '../../word-list/state/word-list.model';
import {start} from 'repl';

interface IWord {
  meaning: string;
  reading: string;
  starts: number[];
  length: number;
  context: string;
  occurrences: string[];
  nodes: HTMLElement[];
}

export class ContentProcessor {
  public textContent: string = '';
  words: IWord[] = [];

  public disabled: boolean = false;

  highlitedWord: IWord|null = null;

  constructor(private document: Document, private contentElement: Node) {
    this.document.addEventListener('pointerup', this.onUp);
    // this.document.addEventListener('touchup', this.onUp);
  }

  destroy() {
    this.document.removeEventListener('pointerup', this.onUp);
  }

  highlitWord(context: string|null) {
    if(this.highlitedWord != null)
      this.highlitedWord.nodes.forEach(node => node.classList.remove('highlight'));

    if(context === null) {
      this.highlitedWord = null;
      return;
    }

    this.highlitedWord = this.words.find(w => w.context === context);
    if(this.highlitedWord == null) {return;}
    this.highlitedWord.nodes.forEach(node => node.classList.add('highlight'));
  }

  pointerover$ = new Subject<string>();

  updateWords(wordList: Word[]) {
    this.words.filter(word => wordList.find(w => w.context === word.context) == null)
      .forEach(word => {
        const context = word.context;
        const starts: {index: number, length: number}[] = [];
        let i = -1;
        let match: RegExpMatchArray;
        const regexp = new RegExp([context,
          ...word.occurrences.map(text => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'))
        ].join('|'))

        while ((match = this.textContent.substr(i + 1).match(regexp)) != null) {
          starts.push({index: i + match.index + 1, length: match[0].length});
          i += match.index + match[0].length;
        }

        starts.forEach(start => {
          const ruby = this.nodes[start.index].parentNode;
          for (let i = start.index; i <= start.index + start.length - 1; ++i) {
            this.nodes[i].classList.remove('active');
            this.nodes[i].parentNode.parentNode.insertBefore(this.nodes[i], this.nodes[i].parentNode)
          }
          ruby.parentNode.removeChild(ruby);
        });
      });

    wordList
      .filter(w => this.words.find(word => word.context === w.context) == null)
      .forEach(w => {
        const context = w.context;
        const starts: {index: number, length: number}[] = [];
        let i = -1;
        let match: RegExpMatchArray;
        const regexp = new RegExp([context,
          ...w.occurrences.map(text => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'))
        ].join('|'))

        while ((match = this.textContent.substr(i + 1).match(regexp)) != null) {
          starts.push({index: i + match.index + 1, length: match[0].length});
          i += match.index + match[0].length;
        }

        const nodes: HTMLElement[] = [];

        this.words.push({
          context,
          starts: starts.map(s => s.index),
          length: context.length,
          reading: w.reading,
          occurrences: w.occurrences,
          meaning: w.meaning.join(', '),
          nodes: nodes
        });

        starts.forEach(start => {
          const ruby = this.document.createElement('ruby');
          ruby.classList.add('word-whole');
          this.nodes[start.index].parentNode.insertBefore(ruby, this.nodes[start.index]);

          ruby.setAttribute('data-md-tooltip', w.meaning.join(', '));

          for (let i = start.index; i <= start.index + start.length - 1; ++i) {
            this.nodes[i].classList.add('active');
            this.nodes[i].remove();
            ruby.appendChild(this.nodes[i]);
          }

          const pn = this.nodes[start.index].parentNode;

          if(pn.parentNode == null || (pn.parentNode as HTMLElement).tagName !== 'RUBY') {
            const rt = this.document.createElement('rt');
            rt.innerText = w.reading;
            ruby.appendChild(rt);
          }

          ruby.onpointerover = (event) => {
            this.pointerover$.next(context);
          };
          ruby.onpointerleave = (event) => {
            this.pointerover$.next(null);
          };

          nodes.push(ruby);
        });
      });
  }

  _selectedWordsNodes: HTMLElement[] = [];

  _selectedWords$: Word[];

  set selectedWords(val: Word[]) {
    this._selectedWords$.filter(word => !val.find(w => w.context == word.context)).forEach(word => {

    });
  }

  private onUp = () => {
    if(this.disabled)
      return;

    if (this.selectMode) {
      const context = this.getCurrentWord();
      this.newWord$.next(context);

      this.selectionEnd = this.selectionStart = null;
      this.selectMode = false;
    }
  };

  public readonly newWord$ = new Subject<string>();
  public readonly removeWord$ = new Subject<string>();

  private globalIndex: number = 0;
  private selectionStart: number | null = null;
  private selectionEnd: number | null = null;
  private selectMode: boolean = false;
  private nodes: HTMLElement[] = [];

  getWordIndex(event: MouseEvent): number {
    return Number((event.target as HTMLElement).getAttribute('word-index'));
  }

  private onLetterPointerDown(event) {
    if(this.disabled)
      return;

    const index = this.getWordIndex(event);
    if (this.nodes[index].className.indexOf('active') != -1) {
      this.removeWord$.next(this.words.find(word => {
        return word.starts.find(start => start <= index && start + word.length - 1 >= index) != null;
      }).context);
      return;
    }

    console.log(`onpointerdown index: ${this.getWordIndex(event)}`);
    this.selectMode = true;
    this.setActiveSelection(this.getWordIndex(event), this.getWordIndex(event));
  }

  private onLetterPointerOver(event: MouseEvent) {
    if (this.disabled) {
      return;
    }

    if (!this.selectMode) {
      return;
    }
    console.log(`onpointermove index: ${this.getWordIndex(event)}`);

    let end = this.getWordIndex(event);
    let start = this.selectionStart;

    if (this.selectionStart > end) {
      start = end;
      end = this.selectionEnd;
    }

    this.setActiveSelection(start, end);
  }

  preprocessContent() {
    this.globalIndex = 0;
    this.nodes = [];
    this.preprocessNode(this.contentElement);
  }

  preprocessNode(node: Node): void {
    // console.log('preprocessNode --- ' + (node as any).tagName);
    Array.from(node.childNodes).forEach(n => {
      if (n.nodeType === Node.TEXT_NODE) {
        const nodes: Node[] = [];

        // console.log('NEW TEXT NODE');

        for (const letter of n.textContent) {
          const span = this.document.createElement('span');
          this.textContent += letter;
          span.className = 'word';
          span.setAttribute('word-index', this.globalIndex.toString());
          span.appendChild(this.document.createTextNode(letter));

          span.onpointerdown = event => this.onLetterPointerDown(event);
          span.onpointerover = event => this.onLetterPointerOver(event);

          // console.log('inserting', span.textContent);

          nodes.push(span);
          this.nodes.push(span);
          ++this.globalIndex;
        }


        nodes.forEach((nn, i) => {
          if (i === 0) {
            n.parentNode.insertBefore(nn, n.nextSibling);
          } else {
            n.parentNode.insertBefore(nn, nodes[i - 1].nextSibling);
          }
        });

        n.remove();

      } else if (n && n instanceof HTMLElement) {
        if (n.classList.contains('no-parse')) { return; }
        if (n.tagName === 'RT' || n.tagName === 'RP') { return; }
        this.preprocessNode(n);
      }
    });

    // console.log('preprocessNode --- END' + (node as any).tagName);
  }

  setActiveSelection(start: number, end: number) {
    if (this.selectionStart != null && this.selectionEnd != null) {
      for (let i = this.selectionStart; i <= this.selectionEnd; ++i) {
        this.nodes[i].classList.remove('active');
      }
    }

    if (start != null && end != null) {
      for (let i = start; i <= end; ++i) {
        this.nodes[i].classList.add('active');
      }
    }

    this.selectionStart = start;
    this.selectionEnd = end;
  }

  setElement(contentElement: Node) {
    this.contentElement = contentElement;
  }

  private getCurrentWord(): string {
    let word = '';
    for (let i = this.selectionStart; i <= this.selectionEnd; ++i) {
      word += this.nodes[i].textContent;
    }

    return word;
  }
}
