import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CharacterStore} from './character.store';
import {Character} from './character.model';
import {CharacterQuery} from './character.query';
import {DocumentQuery} from '../../../state/document.query';
import {finalize, tap} from 'rxjs/operators';
import {NotificationService} from '../../../../../modules/notification/state/notification.service';
import {HashMap} from '@datorama/akita';
import {Observable, of} from 'rxjs';
import {WordListCharacterService} from '../../../word-list/state/word-list-character.service';

@Injectable({providedIn: 'root'})
export class CharacterService {

  constructor(private store: CharacterStore,
              private query: CharacterQuery,
              private wordListCharacterService: WordListCharacterService,
              private documentQuery: DocumentQuery,
              private notificationService: NotificationService,
              private http: HttpClient) {
  }

  get(character: Character) {
  }

  add$(character: string, language: string): Observable<Character> {
    if (this.query.getValue().loadingCharacters.includes(character)) {
      return of(null);
    }

    const document = this.documentQuery.getValue();
    const params: HashMap<string> = {};
    if (document.id) {
      params.document = document.id.toString();
    }

    this.store.update(state => ({...state, loadingCharacters: [...state.loadingCharacters, character]}));
    return this.http.put<Character>(`/api/characters/${language}/${character}/`, {}, {params})
      .pipe(
        tap(character => {
          this.wordListCharacterService.addCharacter(character.literal);
          this.setActive(character.literal);
          this.store.add(character);
        }),
        this.notificationService.catchErrorOperator(`Could not add ${character}`),
        finalize(() => {
          this.store.setLoading(false);
          this.store.update(state => ({...state, loadingCharacters: state.loadingCharacters.filter(c => c != character)}));
        }));
  }

  remove$(character: string, language: string): Observable<null> {
    if (this.query.getValue().loadingCharacters.includes(character)) {
      return of(null);
    }

    const document = this.documentQuery.getValue();
    this.store.update(state => ({...state, loadingCharacters: [...state.loadingCharacters, character]}));
    return this.http.delete<null>(`/api/characters/${language}/${character}/`)
      .pipe(
        tap(() => {
          this.wordListCharacterService.removeCharacter(character);
          this.store.remove(character);
        }),
        this.notificationService.catchErrorOperator(`Could not remove ${character}`),
        finalize(() => {
          this.store.setLoading(false);
          this.store.update(state => ({...state, loadingCharacters: state.loadingCharacters.filter(c => c != character)}));
        })
      );
  }

  setActive(char: string) {
    this.store.setActive(char);
  }

  set(characters: Character[]) {
    this.store.set(characters);
    this.store.setActive(null);
  }
}
