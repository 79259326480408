import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {Character, CharacterState} from './character.model';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'Character', idKey: 'literal'})
export class CharacterStore extends EntityStore<CharacterState, Character, string> {
  constructor() {
    super({
      loadingCharacters: []
    });
  }

}

