import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { EBookStub } from './ebook-stub.model';

export interface EBookState extends EntityState<EBookStub>, ActiveState {
  uploadProgress: number;
  uploading: boolean;
  state: 'uploading'|'default'
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'EBook' , resettable: true})
export class EBookStore extends EntityStore<EBookState, EBookStub> {

  constructor() {
    super({
      loading: false,
      uploading: false,
      state: 'default',
      uploadProgress: 0.0,
      error: null
    });
  }

}

