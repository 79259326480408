import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { Word } from './word-list.model';
import {Injectable} from '@angular/core';

export interface WordListState extends EntityState<Word>, ActiveState<string> {
  addingWord: boolean;
  activeWord: string|null;
  editedWordId: string|null;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'WordList', idKey: 'context' })
export class WordListStore extends EntityStore<WordListState, Word> {

  constructor() {
    super({
      addingWord: false,
      activeWord: null,
      active: null,
      editedWordId: null,
    });
  }
}
