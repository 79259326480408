import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EBookStore, EBookState } from './ebook-stub.store';
import { EBookStub } from './ebook-stub.model';

@Injectable({
  providedIn: 'root'
})
export class EBookQuery extends QueryEntity<EBookState, EBookStub> {

  constructor(protected store: EBookStore) {
    super(store);
  }

  selectUploadingVocab() {
    return this.select('uploading')
  }
}
