import { Component, OnInit } from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {WordListQuery} from '../word-list/state/word-list.query';
import {CharacterQuery} from '../word-details/state/character/character.query';
import {map} from 'rxjs/operators';
import {Word} from '../word-list/state/word-list.model';

@Component({
  selector: 'lng-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {
  activeCharacter$ = this.characterQuery.selectActive();
  word$: Observable<Word> = this.wordListQuery.selectEdited();

  constructor(private wordListQuery: WordListQuery, private characterQuery: CharacterQuery) { }
}
