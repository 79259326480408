import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import {Ebook, EbookWordExtended, EbookWordStatus} from '../state/word/ebook-word.model';
import {FormControl} from '@ng-stack/forms';
import {DOCUMENT} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {HlCharacter} from './word2-characters.pipe';
import {Character} from '../../document/word-details/state/character/character.model';

@UntilDestroy()
@Component({
  selector: 'lng-word-selection',
  templateUrl: './word-selection.component.html',
  styleUrls: ['./word-selection.component.scss']
})
export class WordSelectionComponent implements OnInit, AfterViewInit {
  EbookWordStatus = EbookWordStatus;
  allWords: EbookWordExtended[] = [];
  words: EbookWordExtended[] = [];
  @Input() isLoading: boolean = true;
  @Input() ebook: Ebook;
  @Input() characters: Character[] = [];
  acceptedCount = 0;
  rejectedCount = 0;
  pendingCount = 0;
  modeFc = new FormControl<EbookWordStatus>(EbookWordStatus.Pending);
  tableHeight: string = '100px';
  tableWidth: string = '100px';

  @Output() rejectWord = new EventEmitter<EbookWordExtended>();
  @Output() acceptWord = new EventEmitter<EbookWordExtended>();
  @Output() editWord = new EventEmitter<EbookWordExtended>();
  @Output() toggleCharacter = new EventEmitter<HlCharacter>();
  @Output() finalizeDocument = new EventEmitter<void>();
  @Output() deleteDocument = new EventEmitter<void>();

  constructor(@Inject(DOCUMENT) private document: Document, private element: ElementRef) {
  }

  @Input('words') set _words(value: EbookWordExtended[]) {
    this.allWords = value;
    this.words = value.filter(word => word.status === this.modeFc.value);
    this.acceptedCount = this.rejectedCount = this.pendingCount = 0;
    value.forEach(word => {
      switch (word.status) {
        case EbookWordStatus.Pending:
          ++this.pendingCount;
          break;
        case EbookWordStatus.Rejected:
          ++this.rejectedCount;
          break;
        case EbookWordStatus.Accepted:
          ++this.acceptedCount;
          break;
      }
    });
  }

  trackById = (index: number, word: EbookWordExtended) => word.id;

  ngOnInit(): void {
    this.modeFc.valueChanges.pipe(untilDestroyed(this)).subscribe(
      value => this.words = this.allWords.filter(word => word.status === value)
    );
  }

  ngAfterViewInit(): void {
    this.recalculateTableSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.recalculateTableSize();
  }

  private recalculateTableSize() {
    this.tableHeight = `${this.document.body.scrollHeight - 200}px`;
    this.tableWidth = `${(this.element.nativeElement as HTMLElement).clientWidth}px`;
  }
}
