import {WordListStore} from './word-list.store';
import {createDuplicateWordNotification, Word} from './word-list.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, distinctUntilChanged, finalize, map, mergeMap, take, tap} from 'rxjs/operators';
import {action, HashMap, ID} from '@datorama/akita';
import {WordListQuery} from './word-list.query';
import {DocumentQuery} from '../../state/document.query';
import {WordDetailsFormState} from '../../word-details/word-details.component';
import {NotificationService} from '../../../../modules/notification/state/notification.service';
import {createGeneralNotification} from '../../../../modules/notification/state/notification.model';
import {throwError} from 'rxjs';
import {DetailsComponent} from '../../details/details.component';
import {NzDrawerRef, NzDrawerService} from 'ng-zorro-antd/drawer';
import {CharacterService} from '../../word-details/state/character/character.service';

@Injectable({providedIn: 'root'})
export class WordListService {
  private drawerRef: NzDrawerRef;

  constructor(private wordListStore: WordListStore,
              private wordListQuery: WordListQuery,
              private notificationService: NotificationService,
              private documentQuery: DocumentQuery,
              private drawerService: NzDrawerService,
              private characterService: CharacterService,
              protected http: HttpClient) {
    this.wordListStore = wordListStore;

    this.wordListQuery.selectEdited().pipe(distinctUntilChanged((a, b) => !!a === !!b))
      .subscribe(editedWord => editedWord ? this.showEditDrawer$(editedWord) : this.drawerRef?.close());
  }

  addWord(context: string) {
    this.wordListStore.update(state => ({...state, addingWord: true, loading: true, activeWord: context}));
    this.documentQuery.select(document => document.id).pipe(
      take(1),
      mergeMap(documentId => this.http.post<any>(`/api/documents/${documentId}/words/`, {word: context}).pipe(map(word => [word, documentId]))),
      finalize(() => this.wordListStore.update(state => ({...state, addingWord: false, loading: false, activeWord: null})))
    ).subscribe(([word, documentId]) => {
      this.wordListStore.add(word);
      console.log(word.documents.length);
      if (word.documents.length > 1) {
        this.notificationService.add(createDuplicateWordNotification({
          word: word.context,
          documentId: documentId,
          documents: word.documents.filter(doc => doc.id !== documentId)
        }));
      }
    });
  }

  removeWord(context: ID) {
    this.wordListStore.setLoading(true);
    this.documentQuery.select(document => document.id).pipe(
      take(1),
      mergeMap(documentId => this.http.delete(`/api/documents/${documentId}/words/${context}/`)),
      finalize(() => this.wordListStore.setLoading(false))
    ).subscribe(
      () => this.wordListStore.remove(context),
      (error) => this.notificationService.add(createGeneralNotification({content: 'Could not complete operation', type: 'danger'}))
    );
  }

  setActive(context: string | null) {
    this.wordListStore.setActive(context);
  }

  @action('editWord')
  editWord(wordId: string) {
    this.wordListStore.update(state => ({...state, editedWordId: wordId}));
  }

  @action('acceptEditWord')
  acceptEditWord(_form: WordDetailsFormState) {
    const form: any = {context: this.wordListQuery.getEdited().context, stem: this.wordListQuery.getEdited().stem, ..._form};
    if (!(form.meaning instanceof Array)) {
      form.meaning = (_form.meaning as string).split('\n').filter(v => v.trim() != '');
    }
    if (!(form.partsOfSpeach instanceof Array)) {
      form.partsOfSpeach = (_form.partsOfSpeach as string).split('\n').filter(v => v.trim() != '');
    }

    const params: HashMap<string> = {};

    if (this.documentQuery.getValue()?.id) {
      params.document = this.documentQuery.getValue().id.toString();
    }


    this.wordListStore.update(state => ({...state, addingWord: true, activeWord: form.context, loading: true}));

    return this.http.put<Word>(`/api/words/${form.context}/`, form, {params})
      .pipe(
        tap(word => {
          this.wordListStore.update(word.context, word);
          this.wordListStore.update(state => ({
            ...state,
            addingWord: false,
            loading: false,
            editedWordId: null,
            activeWord: null
          }));
        }),
        catchError(error => {
          this.wordListStore.setError(error);
          this.wordListStore.setLoading(false);
          return throwError(error);
        })
      );
  }

  cancelEditWord() {
    this.wordListStore.update(state => ({...state, addingWord: false, activeWord: null, editedWordId: null, akitaForm: null}));
  }

  setLoading(loading: boolean) {
    this.wordListStore.setLoading(loading);
  }

  @action('clear')
  clear() {
    this.wordListStore.set([]);
    this.wordListStore.update(state => ({...state, editedWordId: null, activeWord: null}));
  }

  set(words: Word[]) {
    this.wordListStore.set(words);
  }

  updateForm(value: WordDetailsFormState) {
    this.wordListStore.update(state => ({...state, akitaForm: {...value}}));
  }

  showEditDrawer$(word: Word) {
    this.characterService.setActive(null);

    this.drawerRef = this.drawerService.create({
      nzContent: DetailsComponent,
      nzWidth: '400px',
      nzCloseOnNavigation: true,
      nzClosable: false,
    });

    this.drawerRef.afterClose.subscribe(() => {
      this.cancelEditWord();
      this.characterService.setActive(null);
    });
  }
}
