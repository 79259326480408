import { Query } from '@datorama/akita';
import { GeneralStateStore, GeneralState } from './general-state.store';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class GeneralStateQuery extends Query<GeneralState> {

  constructor(protected store: GeneralStateStore) {
    super(store);
  }
}
