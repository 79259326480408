import {Component, OnInit} from '@angular/core';
import {DocumentFormQuery, DocumentFormService, TagState} from './state';
import {filter} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DocumentService} from '../document/state/document.service';
import {FormControl, FormGroup} from '@ng-stack/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RoutableComponent} from '../../services/utils/miscellaneous';
import {DocumentFormat, DocumentFS, DocumentLanguage} from './state/document-form.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {makeCustomizedTurndown} from './markdown';

@UntilDestroy()
@Component({
  selector: 'lng-document-form',
  templateUrl: './text-document-form.component.html',
  styleUrls: ['./text-document-form.component.scss']
})
export class TextDocumentFormComponent extends RoutableComponent implements OnInit {
  form: FormGroup<DocumentFS>;
  loading$: Observable<boolean>;
  uploadProgress$: Observable<number>;
  availableCategories$: Observable<TagState[]>;
  availableTags$: Observable<TagState[]>;
  isEditing$: Observable<boolean>;

  constructor(activatedRoute: ActivatedRoute,
              router: Router,
              private documentService: DocumentService,
              private documentFormService: DocumentFormService,
              private documentFormQuery: DocumentFormQuery) {
    super(activatedRoute, router);
    this.form = new FormGroup<DocumentFS>({
      id: new FormControl(null),
      category: new FormControl([]) as any,
      tags: new FormControl<string[]>([]) as any,
      caption: new FormControl(''),
      source: new FormControl(''),
      format: new FormControl<DocumentFormat>('markdown'),
      language: new FormControl<DocumentLanguage>('ja-jpan'),
      content: new FormControl(''),
    });
  }

  ngOnInit() {
    this.documentFormQuery.form.connect(this.form).pipe(untilDestroyed(this)).subscribe();
    this.documentFormService.clear();
    this.documentService.clear();

    this.form.controls.category.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      value.length > 1 && this.form.controls.category.setValue([value[value.length - 1]] as any)
    });

    this.documentFormQuery.select(state => state.id)
      .pipe(filter(id => id != null), untilDestroyed(this))
      .subscribe(id => this.form.patchValue({...this.documentFormQuery.getValue()} as any));

    this.availableCategories$ = this.documentFormQuery.categories.selectAll();
    this.availableTags$ = this.documentFormQuery.tags.selectAll();
    this.loading$ = this.documentFormQuery.selectLoading();

    this.documentFormService.fetchAvailableCategories();
    this.documentFormService.fetchAvailableTags();
  }

  onSubmit() {
    const submitData = {...this.form.value, category: this.form.value.category[0] || null};
    (this.documentFormQuery.getValue().id == null
      ? this.documentFormService.create(submitData)
      : this.documentFormService.update(submitData)).subscribe((document) => this.router.navigate(['/documents', document.id]));
  }

  onContentPaste($event: ClipboardEvent) {
    const data = $event.clipboardData.getData('text/html');

    // user is pasting plain text, we should not do anything
    if (data === '') {
      return;
    }

    const selectionStart = ($event.target as HTMLTextAreaElement).selectionStart;
    const selectionEnd = ($event.target as HTMLTextAreaElement).selectionEnd;
    const value = this.form.controls.content.value;

    $event.preventDefault();

    if (this.form.controls.format.value === 'html') {
      const newValue = value.slice(0, selectionStart) + data + value.slice(selectionEnd);
      this.form.controls.content.setValue(newValue);
    } else if (this.form.controls.format.value === 'markdown') {
      const markdown = makeCustomizedTurndown().turndown(data);
      const newValue = value.slice(0, selectionStart) + markdown + value.slice(selectionEnd);
      this.form.controls.content.setValue(newValue);
    }
  }
}
