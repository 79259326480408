import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageSelectComponent} from './language-select.component';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {FLAG_ICONS} from './icons';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {PIpesModule} from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    LanguageSelectComponent
  ],
  imports: [
    CommonModule,
    NzIconModule.forChild(FLAG_ICONS),
    NzToolTipModule,
    PIpesModule,
  ],
  exports: [
    LanguageSelectComponent
  ]
})
export class LanguageSelectModule {
}
