import { Injectable } from '@angular/core';
import {HashMap, QueryEntity} from '@datorama/akita';
import { CharacterStore } from './character.store';
import {Character, CharacterState} from './character.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CharacterQuery extends QueryEntity<CharacterState, Character, string> {

  constructor(protected store: CharacterStore) {
    super(store);
  }

  selectLoadingCharacter(character: string): Observable<boolean> {
    return this.select(state => state.loadingCharacters).pipe(map(chars => chars.includes(character)));
  }

  selectLoadingCharacters(): Observable<HashMap<boolean>> {
    return this.select(state => state.loadingCharacters).pipe(map(chars => {
      const r = {};
      chars.forEach(c => r[c] = true);
      return r;
    }))
  }
}
