import { Query } from '@datorama/akita';
import { PreferencesStore, PreferencesState } from './preferences.store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {DocumentQuery} from '../../views/document/state/document.query';

@Injectable()
export class PreferencesQuery extends Query<PreferencesState> {
  constructor(protected store: PreferencesStore, protected documentQuery: DocumentQuery) {
    super(store);
  }

  getScrollPosition$(documentId: number): Observable<number|null> {
    return this.selectLoading().pipe(
      filter(isLoading => !isLoading),
      map(() => {
        if(this.getValue().lastDocumentId === documentId)
          return this.getValue().lastDocumentScroll || 0;
        return 0;
      }));
  }
}
