import {AvailableTagsStore, DocumentFormStore} from './document-form.store';
import {Injectable} from '@angular/core';
import {last, map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {HttpClient, HttpEventType, HttpProgressEvent} from '@angular/common/http';
import {DocumentListQuery} from '../../document-list/state/document/document-list.query';
import {DocumentListStore} from '../../document-list/state/document/document-list.store';
import {createInitialState, DocumentState, DocumentStatus, DocumentStore} from '../../document/state/document.store';
import {WordListStore} from '../../document/word-list/state/word-list.store';
import {DocumentService} from '../../document/state/document.service';
import {DocumentQuery} from '../../document/state/document.query';
import {DocumentFormQuery} from './document-form.query';
import {DocumentListService} from '../../document-list/state/document/document-list.service';
import {createFormInitialState, DocumentFormat, DocumentFS} from './document-form.model';
import {setLoading} from '@datorama/akita';
import {setError} from '../../../utils/rxjs/akita';
import {DocumentStub} from '../../document-list/state/document/document-list.model';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class DocumentFormService {

  constructor(private documentFormStore: DocumentFormStore,
              private documentStore: DocumentStore,
              private wordListStore: WordListStore,
              private documentService: DocumentService,
              private documentListStore: DocumentListStore,
              private documentQuery: DocumentQuery,
              private documentFormQuery: DocumentFormQuery,
              private documentListService: DocumentListService,
              private router: Router,
              private http: HttpClient,
              private documentListQuery: DocumentListQuery) {
  }

  fetchAvailableCategories() {
    this._fetchTags(this.documentFormStore.categories, '/api/documents/categories/');
  }

  fetchAvailableTags() {
    this._fetchTags(this.documentFormStore.tags, '/api/documents/tags/');
  }

  createVideo(document: DocumentFS, files: { video: File, subtitles: File }): Observable<DocumentStub> {
    const formData = new FormData();
    formData.set('subtitles', files.subtitles, files.subtitles.name);
    formData.set('video', files.video, files.video.name);
    for (const [k, value] of Object.entries(document)) {
      formData.set(k, Array.isArray(value) ? JSON.stringify(value) : value);
    }

    return this.http.post<any>('/api/documents/', formData, {reportProgress: true, observe: 'events'})
      .pipe(
        setLoading(this.documentFormStore),
        setError(this.documentFormStore),
        tap((event: HttpProgressEvent) => (event.type === HttpEventType.UploadProgress)
          && this.documentFormStore.update(state => ({
            ...state,
            uploadProgress: Math.round((event.loaded / event.total) * 100.0)
          }))),
        last(),
        map((event: any) => event.body),
        tap(data => this.insertDocument(data, 'video')),
        tap(() => this.documentFormStore.update({uploadProgress: 100.0})),
      ) as Observable<DocumentStub>;
  }

  create(document: DocumentFS, files?: { video: File, subtitles: File }) {
    return this.http.post<DocumentState>('/api/documents/', document)
      .pipe(
        setLoading(this.documentFormStore),
        setError(this.documentFormStore),
        tap(data => this.insertDocument(data, 'markdown'))
      );
  }

  loadDocumentForEdit(documentId: number) {
    this.http.get<DocumentState>(`/api/documents/${documentId}/`)
      .pipe(setLoading(this.documentFormStore))
      .subscribe(data => {
        if (data.state !== DocumentStatus.DONE && data.state !== DocumentStatus.CUSTOMIZING) {
          this.documentFormStore.setError('Document is in wrong state');
        } else {
          this.documentFormStore.update(state => ({...state, ...data}));
        }
      }, error => {
        if (error.status === 404) {
          this.router.navigate(['/all'], {replaceUrl: true});
        } else {
          this.documentFormStore.setError(error);
        }
      });
  }

  clear() {
    this.documentFormStore.update(state => createFormInitialState());
  }

  update(param: any) {
    const id = this.documentFormQuery.getValue().id;
    return this.http.put<DocumentState>(`/api/documents/${id}/`, param)
      .pipe(
        setLoading(this.documentFormStore),
        tap((data) => {
          this.documentService.setDocument(data);
          this.documentListService.loadDocuments$(true);
        }),
        setError(this.documentFormStore)
      );
  }

  private insertDocument(document: DocumentStub, format: DocumentFormat) {
    this.documentStore.update({akitaForm: {...createInitialState(), language: document.language, format: format}});
    this.documentFormStore.update(state => ({...state, akitaForm: createInitialState()}));
    this.documentStore.update(state => document);
    this.documentListStore.add(document, {prepend: true});

    if (document.category != null && !this.documentListQuery.getValue().categories.includes(document.category)) {
      this.documentListStore.update(({categories}) => ({categories: [...categories, document.category]}));
    }
  }

  private _fetchTags(store: AvailableTagsStore, url: string) {
    this.http.get<string[]>(url).pipe(setLoading(store)).subscribe(
      data => {
        store.clear();
        store.set(data.map(tag => ({tag})));
      },
      error => store.setError(error.message || 'ERROR')
    );
  }
}
