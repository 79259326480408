import {Query} from '@datorama/akita';
import {DocumentState, DocumentStatus, DocumentStore} from './document.store';
import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {GeneralStateQuery} from '../../../model/general-state';
import {WordDetailsQuery} from '../word-details/state/word-details/word-details.query';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DocumentQuery extends Query<DocumentState> {

  constructor(protected store: DocumentStore,
              private wordDetailsQuery: WordDetailsQuery,
              private generalStateQuery: GeneralStateQuery) {
    super(store);
  }

  allowWordList$(): Observable<boolean> {
    return this.select(store => (
      store.allowWordList &&
      store.state !== DocumentStatus.ERROR &&
      store.state !== DocumentStatus.CUSTOMIZING &&
      store.state !== DocumentStatus.PROCESSING)).pipe(distinctUntilChanged());
  }

  showWordDetails$(): Observable<boolean> {
    return this.wordDetailsQuery.selectShow()
  }

  // showWordList$() {
  //   combineLatest(
  //     this.newDocument$.pipe(map(isNew => !isNew)),
  //     this.generalStateQuery.select(state => state.showWordList)
  //   ).pipe(map(arr => arr.indexOf(false) === -1));
  // }
}
