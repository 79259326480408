<!--<lng-navbar></lng-navbar>-->
<nz-layout class="full-height">
  <nz-sider *ngIf="loggedIn$ | async" nzCollapsible [nzCollapsed]="isSidebarCollapsed$ | async" [nzTrigger]="null">
    <lng-navbar></lng-navbar>
  </nz-sider>
  <nz-layout style="background-color: white;">
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
<ext-modal-outlet></ext-modal-outlet>
<lng-notification-outlet></lng-notification-outlet>
