import { Query } from '@datorama/akita';
import { UserStore } from './user.store';
import { User } from './user.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserQuery extends Query<User> {

  constructor(protected store: UserStore) {
    super(store);
  }

  selectIsLoggedIn(): Observable<boolean> {
    return this.select(store => store.email).pipe(map(email => email != null));
  }

  isLoggedIn() {
    return this.getValue().email != null;
  }
}
