import {catchError, map} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {camelize, uncamelize} from '../utils/camelize';


@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.body != null && !(request.body instanceof FormData)) {
      request = request.clone({
        body: uncamelize(request.body)
      });
    }

    let router: Router = this.injector.get(Router);

    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401: {
              router.navigate(['/login']);
              break;
            }
            case 400: {
              return throwError(typeof err.error == 'string' ? {__general__: [err.statusText], _original: err} : {...err.error, _original: err});
            }
            case 504: {
              return throwError({__general__: ['Could not connect to the server'], _original: err});
            }
          }
        }
        return throwError({__general__: [`<strong>${err.status}</strong>: ${err.statusText || 'Nieoczekiwany błąd'}`], _original: err});
      }),
      map((event: HttpEvent<any>) => {
        // camelize only JSON, otherwise this breaks SVG icon, xml, etc
        if (event instanceof HttpResponse && typeof event.body != 'string') {
          return event.clone({body: camelize(event.body)});
        }
        return event;
      }));
  }
}
