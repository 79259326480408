import {AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {ContentProcessor} from './services/content-processor.service';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {PreferencesQuery, PreferencesService} from '../../../model/preferences';
import {ScrollDispatcher} from '@angular/cdk/overlay';
import {DocumentState, DocumentStatus} from '../state/document.store';
import {DocumentQuery} from '../state/document.query';
import {DocumentService} from '../state/document.service';
import {WordListQuery} from '../word-list/state/word-list.query';
import {WordListService} from '../word-list/state/word-list.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Word} from '../word-list/state/word-list.model';
import {switchMap, take} from 'rxjs/operators';
import {DocumentStub} from '../../document-list/state/document/document-list.model';

@UntilDestroy()
@Component({
  selector: 'lng-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy, AfterViewInit {
  public DocumentStatus = DocumentStatus;
  public hideEditor$: Observable<boolean>;
  public hideError$: Observable<boolean>;
  @Input() loading: boolean = true;
  @Input() set words(words: Word[]) {
    this.words$.next(words);
  }
  public contentProcessor: ContentProcessor;
  @ViewChild('contentElement') contentElement: ElementRef;
  document$ = new ReplaySubject<DocumentState>();
  words$ = new ReplaySubject<Word[]>();

  constructor(private wordListService: WordListService,
              private documentService: DocumentService,
              @Inject(DOCUMENT) private dom: HTMLDocument,
              private query: DocumentQuery,
              private wordQuery: WordListQuery,
              private preferencesQuery: PreferencesQuery,
              private preferencesService: PreferencesService,
              public scroll: ScrollDispatcher,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  @Input() set document(doc: DocumentState) {
    this.document$.next(doc);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.document$.pipe(untilDestroyed(this)).subscribe(document => this.setEditorContent(document));
    this.document$.pipe(take(1), switchMap(() => this.words$), untilDestroyed(this)).subscribe(words => this.contentProcessor.updateWords(words));
    this.wordQuery.selectActiveId().pipe(untilDestroyed(this)).subscribe(context => this.contentProcessor?.highlitWord(context as string));
  }

  ngOnDestroy(): void {
    if (this.contentProcessor != null) {
      this.contentProcessor.destroy();
    }
  }

  private setEditorContent(doc: DocumentState) {
    const content = doc.content;
    if (this.contentProcessor != null) {
      this.contentProcessor.destroy();
    }

    let mainNode = (this.contentElement.nativeElement as HTMLElement);

    while (mainNode.firstChild) {
      mainNode.removeChild(mainNode.firstChild);
    }

    const node = this.dom.createElement('div');
    node.innerHTML = content;

    this.contentProcessor = new ContentProcessor(this.dom, node);
    this.contentProcessor.disabled = doc.locked;
    this.contentProcessor.preprocessContent();
    this.contentProcessor.updateWords(doc.words);

    (this.contentElement.nativeElement as HTMLElement).appendChild(node);

    const container = this.scroll.getAncestorScrollContainers(this.contentElement)[0];

    this.contentProcessor.newWord$.pipe(untilDestroyed(this)).subscribe(word => {
      this.wordListService.addWord(word);
    });
    this.contentProcessor.removeWord$.pipe(untilDestroyed(this)).subscribe(word => {
      this.wordListService.removeWord(word);
    });

    this.contentProcessor.pointerover$.pipe(untilDestroyed(this)).subscribe(word => {
      this.wordListService.setActive(word);
    });
  }

  onBack() {
    this.router.navigate(['/library']);
  }

  deleteDocument(documentId: number) {
    this.documentService.deleteDocument(documentId);
  }

  editDocument(document: DocumentStub) {
    this.documentService.showEditModal(document)
  }

  toggleLock() {
    this.documentService.toggleLock()

  }
}
