<nz-steps [nzCurrent]="currentStep">
  <nz-step nzTitle="Select files"></nz-step>
  <nz-step nzTitle="Customizing"></nz-step>
  <nz-step nzTitle="Processing"></nz-step>
  <nz-step nzTitle="Finish"></nz-step>
</nz-steps>
<form [formGroup]="form">
  <ng-container [ngSwitch]="currentStep">
    <ng-container *ngSwitchCase="0">
      <div class="form-content">
        <div>
          <h2 nz-row nzJustify="center" i18n>Select Files</h2>
          <div nz-row nzJustify="center">
            <lng-language-select formControlName="language"></lng-language-select>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="24" style="text-align: center">
              <p nz-typography nzType="secondary" i18n>Select video file and corresponding subtitles file (SRT)</p>
            </div>
          </div>
          <div nz-row [nzGutter]="32" class="main-content">
            <ngx-file-drop
              nz-col
              (click)="videoFileInput.click()"
              [nzSpan]="12"
              [nzSm]="12"
              [nzXs]="24"
              (onFileDrop)="onVideoSelected($event)">
              <ng-template ngx-file-drop-content-tmp>
                <div class="ant-upload ant-upload-drag ng-star-inserted">
                  <div class="ant-upload-btn ant-upload" tabindex="0" role="button">
                    <div class="ant-upload-drag-container">
                      <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="video-camera"></i>
                      </p>
                      <ng-container *ngIf="!videoFile; else videoMetadata">
                        <p class="ant-upload-text">Video File</p>
                        <p class="ant-upload-hint">Click or drag file to this area to upload</p>
                      </ng-container>

                      <ng-template #videoMetadata>
                        <p class="ant-upload-hint">
                          {{videoFile.name}}<br/>
                          {{videoFile.type}}<br/>
                          {{videoFile.size | filesize}}
                        </p>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-file-drop>
            <input hidden type="file" #videoFileInput (change)="onVideoSelected($event)">

            <ngx-file-drop
              nz-col
              (click)="subtitleFileInput.click()"
              [nzSpan]="12"
              [nzSm]="12"
              [nzXs]="24"
              (onFileDrop)="onSubtitleSelected($event)">
              <ng-template ngx-file-drop-content-tmp>
                <div class="ant-upload ant-upload-drag ng-star-inserted">
                  <div class="ant-upload-btn ant-upload" tabindex="0" role="button">
                    <div class="ant-upload-drag-container">
                      <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="file-text"></i>
                      </p>
                      <ng-container *ngIf="!subtitlesFile; else subtitleMetadata">
                        <p class="ant-upload-text">Subtitle File</p>
                        <p class="ant-upload-hint">Click or drag file to this area to upload</p>
                      </ng-container>

                      <ng-template #subtitleMetadata>
                        <p class="ant-upload-hint">
                          {{subtitlesFile.name}}<br/>
                          {{subtitlesFile.type}}<br/>
                          {{subtitlesFile.size | filesize}}
                        </p>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-file-drop>
            <input hidden type="file" #subtitleFileInput (change)="onSubtitleSelected($event)">
          </div>
          <div *ngIf="form.hasError('__general__')" class="file-error">
            {{form.errors.__general__[0]}}
          </div>
          <div nz-row nzJustify="center">
            <button nz-button style="min-width: 150px;" (click)="nextStep()">
              <ng-container i18n>Next</ng-container>
              <i nz-icon nzType="right"></i></button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <div class="form-content">
        <div>
          <h2 nz-row nzJustify="center" i18n>Customize</h2>
          <div nz-row class="main-content">
            <nz-form-item>
              <nz-form-control [nzErrorTip]="form.controls.caption.errors | concatErrors">
                <input nz-input placeholder="Title" i18n-placeholder formControlName="caption">
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-select formControlName="category" nzMode="tags" nzMaxTagCount="1" nzAllowClear nzPlaceHolder="Choose category" i18n-nzPlaceHolder>
                <nz-option *ngFor="let category of (availableCategories$ | async)" [nzValue]="category.tag"
                           [nzLabel]="category.tag"></nz-option>
              </nz-select>
            </nz-form-item>
            <nz-form-item>
              <nz-select nzMode="tags" formControlName="tags" nzPlaceHolder="Choose tags" i18n-nzPlaceHolder>
                <nz-option *ngFor="let tag of (availableTags$ | async)" [nzLabel]="tag.tag"
                           [nzValue]="tag.tag"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>

          <div nz-row nzJustify="center" class="main-content">
            <button nz-button style="min-width: 150px;" (click)="prevStep()">
              <i nz-icon nzType="left"></i>
              <ng-container i18n>Back</ng-container>
            </button>&nbsp;
            <button nz-button style="min-width: 150px;" (click)="upload()">
              <ng-container i18n>Upload</ng-container>
              <i nz-icon nzType="right"></i></button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="form-content">
        <div style="text-align: center;">
          <ng-container *ngIf="documentId; else uploadRef">
            <nz-progress [nzPercent]="processingProgress" nzType="circle"></nz-progress>
            <ng-container *ngIf="processingProgress < 100.0; else processingCompletedRef">
              <p nz-typography nzType="secondary" style="margin-top: 10px;" i18n>Your file is being processed...</p>
              <p nz-typography nzType="secondary" style="margin-top: 10px;" i18n>You can exit this page, and return later.</p>
            </ng-container>
            <ng-template #processingCompletedRef>
              <p nz-typography nzType="secondary" style="margin-top: 10px;" i18n>The file has been processed successfully! Your document will open now...</p>
            </ng-template>
          </ng-container>
          <ng-template #uploadRef>
            <nz-progress [nzPercent]="uploadProgress$ | async" nzType="circle"></nz-progress>
            <p nz-typography nzType="secondary" style="margin-top: 10px;">
              <ng-container *ngIf="(uploadProgress$ | async) < 100.0; else uploadCompletedRef" i18n>Your file is being uploaded...</ng-container>
              <ng-template #uploadCompletedRef>Your upload was successful! The file will be processed...</ng-template>
            </p>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
