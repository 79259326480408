import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageNamePipe } from './language-name/language-name.pipe';
import { ConcatErrorsPipe } from './concat-errors/concat-errors.pipe';



@NgModule({
  declarations: [
    LanguageNamePipe,
    ConcatErrorsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LanguageNamePipe,
    ConcatErrorsPipe
  ]
})
export class PIpesModule { }
