import {action} from '@datorama/akita';
import {createInitialState, DocumentState, DocumentStatus, DocumentStore} from './document.store';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, exhaustMap, filter, finalize, map, mergeMap, take, takeWhile, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DocumentQuery} from './document.query';
import {interval, Observable, of} from 'rxjs';
import {EditorService} from '../editor/state';
import {DocumentListStore} from '../../document-list/state/document/document-list.store';
import {DocumentListQuery} from '../../document-list/state/document/document-list.query';
import {WordListService} from '../word-list/state/word-list.service';
import {CharacterService} from '../word-details/state/character/character.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DocumentStub} from '../../document-list/state/document/document-list.model';
import {ModalService} from '../../../modules/modal/state/modal.service';
import {createModal} from '../../../modules/modal/state/modal.model';
import {DOCUMENT_EDIT_MODAL_ID, DocumentEditModal} from '../../document-list/document-edit-modal/document-edit-modal.modal';


interface IDocumentProgress {
  state: number;
  progress: number;
}

@Injectable({providedIn: 'root'})
export class DocumentService {

  constructor(private documentStore: DocumentStore,
              private wordListService: WordListService,
              private documentListStore: DocumentListStore,
              private router: Router,
              private http: HttpClient,
              private title: Title,
              private editorService: EditorService,
              private documentListQuery: DocumentListQuery,
              private documentQuery: DocumentQuery,
              private characterService: CharacterService,
              private modalService2: ModalService,
              private modalService: NzModalService) {
  }

  load(id: number, force: boolean = false) {
    if (!force && this.documentListQuery.getActiveId() === id && this.documentQuery.getValue().id === id) {
      return of(this.documentQuery.getValue());
    }

    this.documentStore.setLoading(true);
    this.wordListService.setLoading(true);
    this.documentListStore.setActive(id);
    this.wordListService.clear();
    return this.http.get<DocumentState>(`/api/documents/${id}/`)
      .pipe(
        finalize(() => {
          this.documentStore.setLoading(false);
          this.wordListService.setLoading(false);
        }),
        tap(data => {
          this.documentStore.update(state => data);
          this.wordListService.set(data.words);
          this.characterService.set(data.characters);
        }));
  }

  updateProgress(id: number): Observable<IDocumentProgress & { id: number }> {
    return interval(1000).pipe(
      exhaustMap(() => this.http.get<IDocumentProgress>(`/api/documents/${id}/progress/`)),
      tap(data => this.documentStore.update(document => ({...document, progress: data.progress, state: data.state}))),
      map(data => ({...data, id: id})),
      takeWhile(data => data.state === DocumentStatus.PROCESSING, true),
      catchError(error => {
        this.documentStore.update(document => ({...document, state: DocumentStatus.ERROR}));
        this.documentListStore.update(id, document => ({...document, state: DocumentStatus.ERROR}));
        return of({...error, id});
      })
    );

  }

  loadMostRecent() {
    this.documentListQuery.select(state => state).pipe(
      filter(state => !state.loading),
      take(1),
      map(state => state.ids.length === 0 ? null : state.ids[0] as number))
      .subscribe(id => {
        if (id != null) {
          this.load(id).subscribe();
        } else {
          this.router.navigate(['/documents/form'], {replaceUrl: true});
        }
      });
  }

  @action('clear')
  clear() {
    this.editorService.clear();
    this.documentListStore.setActive(null);
    this.documentStore.update(createInitialState());
    this.wordListService.clear();
  }

  @action('deleteDocument')
  deleteDocument(documentId: number) {
    const confirmModal = this.modalService.confirm({
      nzTitle: 'Delete document',
      nzContent: 'Are you sure you want to permanently remove this document?',
      nzOnOk: () =>
        this.documentListQuery.selectEntity(documentId)
          .pipe(
            take(1),
            filter(activeDocument => activeDocument != null),
            mergeMap(activeDocument => this.http.delete(`/api/documents/${activeDocument.id}/`)),
            finalize(() => this.documentStore.setLoading(false)),
            tap(() => {
              this.documentStore.update(state => ({...state, id: null}));

              const docCategory = this.documentListQuery.getEntity(documentId).category;

              if (docCategory !== null && this.documentListQuery.getAll({filterBy: doc => doc.category === docCategory}).length === 1) {
                let newCat = docCategory;

                if (this.documentListQuery.getValue().activeCategory === docCategory) {
                  newCat = null;
                }

                this.documentListStore.update(state => ({
                  ...state,
                  categories: state.categories.filter(c => c !== docCategory),
                  activeCategory: newCat
                }));
              }

              if (this.documentListQuery.getActiveId() === documentId) {
                this.router.navigate(['/library']);
              }

              this.documentListStore.setActive(null);
              this.documentListStore.remove(documentId);
            })
          ).toPromise()
    });
  }

  @action('deleteActive')
  deleteActive() {
    this.deleteDocument(this.documentQuery.getValue().id);
  }

  showEditModal(document: DocumentStub) {
    this.modalService2.show(createModal<DocumentEditModal>({id: DOCUMENT_EDIT_MODAL_ID, document: document}));
  }

  updateMetadata(documentId: number, documentMetadata: { caption: string, category: string, tags: string[] }) {
    this.documentListStore.setError(null);
    this.documentListStore.update({loadingMetadata: true});
    return this.http.patch<DocumentStub>(`/api/documents/${documentId}/`, documentMetadata).pipe(
      finalize(() => this.documentListStore.update({loadingMetadata: false})),
      tap(document => {
        this.documentListStore.update(documentId, document);
        if (documentId === this.documentQuery.getValue().id) {
          this.documentStore.update({category: document.category, tags: document.tags, caption: document.caption})
        }
        if (document.category != null && !this.documentListQuery.getValue().categories.includes(document.category)) {
          this.documentListStore.update(({categories}) => ({categories: [...categories, document.category]}));
        }
      }),
      catchError(error => {
        this.documentListStore.setError(error);
        return of(error);
      }),
    );
  }

  toggleLock() {
    this.documentStore.setLoading(true);
    this.documentQuery.select(state => state).pipe(
      take(1),
      filter(state => state.id != null),
      mergeMap(document => this.http.post(`/api/documents/${document.id}/lock/`, {lock: !document.locked}).pipe(map(() => [!document.locked, document as any] as [boolean, DocumentStub])))
    ).subscribe(([locked, document]) => {
      this.documentStore.update(state => ({...state, locked, loading: false}));
      this.documentListStore.update(document.id, {locked});
    });
  }

  allowWordList(allow: boolean) {
    if (allow === this.documentQuery.getValue().allowWordList) {
      return;
    }

    this.documentStore.update(state => ({...state, allowWordList: allow}));
  }

  setDocument(document: DocumentState) {
    this.documentStore.update(state => ({...state, ...document}));
  }

  deleteDocumentStub(documentId: number) {
    // this.http.delete(`/api/documents/${documentId}/`).subscribe()
  }
}
