<div style="margin: 16px;">
  <nz-steps [nzCurrent]="currentStep$ | async">
    <nz-step nzTitle="vocabulary.db"></nz-step>
    <nz-step nzTitle="Select Book"></nz-step>
    <!--  <nz-step nzTitle="Upload EBook"></nz-step>-->
    <nz-step nzTitle="Customize Vocabulary"></nz-step>
    <nz-step nzTitle="Finish"></nz-step>
  </nz-steps>
</div>

<ng-container [ngSwitch]="currentStep$ | async" *ngIf="!(errors$ | async); else errorRef">
  <ng-container *ngSwitchCase="0">
    <div class="main-content" *ngIf="!(uploadingVocab$ | async); else uploadRef">
      <ngx-file-drop
        (click)="videoFileInput.click()"
        (onFileDrop)="onVocabularySelection($event)">
        <ng-template ngx-file-drop-content-tmp>
          <div class="ant-upload ant-upload-drag ng-star-inserted">
            <div class="ant-upload-btn ant-upload" tabindex="0" role="button">
              <div class="ant-upload-drag-container">
                <p class="ant-upload-drag-icon">
                  <i nz-icon nzType="database"></i>
                </p>
                <ng-container *ngIf="!vocabularyFile; else videoMetadata">
                  <p class="ant-upload-text">vocab.db File</p>
                  <p class="ant-upload-hint">Click or drag file to this area to upload</p>
                </ng-container>

                <ng-template #videoMetadata>
                  <p class="ant-upload-hint">
                    {{vocabularyFile.name}}<br/>
                    {{vocabularyFile.type}}<br/>
                    {{vocabularyFile.size | filesize}}
                  </p>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-file-drop>
      <input hidden type="file" #videoFileInput (change)="onVocabularySelection($event)">
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="1">
    <div class="document-list ebook-list" *ngIf="!(uploadingVocab$ | async); else uploadRef">
      <nz-card *ngFor="let document of (ebooks$ | async)"
               [nzHoverable]="true"
               [attr.title]="document.title || '<No title>'"
               (click)="selectBook(document)"
               [nzTitle]="document.title || '<No title>'">
        <div class="document ebook-card-content"
             style="background-image: url('/amazon/cover/{{document.asin}}.01.20TRZZZZ.jpg')">
          <div class="word-count">
            <span style="text-align: left;"><strong>{{document.lookupCount}}</strong> Words</span>
            <span style="position: absolute; right: 5px;"><strong>{{document.lang | uppercase}}</strong></span>
          </div>
        </div>
      </nz-card>
    </div>
  </ng-container>

  <!--  <ng-container *ngSwitchCase="2">-->
  <!--    <p i18n>If the book you selected is DRM free you can upload in order to better extract word context.</p>-->
  <!--    <p i18n>This step is optional, if source ebook is not provided context provided by kindle will be used.</p>-->
  <!--    <lng-ebook-card [ebook]="selectedBook$ | async"></lng-ebook-card>-->

  <!--    <a (click)="skipSourceEbook()" i18n>Skip</a>-->
  <!--    <button nz-button nzType="default" i18n>Upload eBook</button>-->
  <!--  </ng-container>-->
  <ng-container *ngSwitchCase="2">
    <ng-container *ngIf="!(processingError$ | async); else errorProcessingRef">
      <ng-container *ngIf="!(errorsDocument$ | async); else errorWordRef">
        <div *ngIf="(processingDocument$ | async) === 100.0; else processingRef">
          <lng-word-selection [ebook]="ebook$ | async"
                              [isLoading]="wordsLoading$ | async"
                              [words]="importedWords$ | async"
                              (acceptWord)="acceptWord($event)"
                              (toggleCharacter)="toggleCharacter($event)"
                              [characters]="characters$ | async"
                              (editWord)="editWord($event)"
                              (finalizeDocument)="finalizeDocument()"
                              (deleteDocument)="deleteDocument()"
                              (rejectWord)="rejectWord($event)"></lng-word-selection>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #errorRef>
  <div class="message-layout">
    <div>
      <nz-result
        i18n-nzTitle
        nzTitle="Processing Failed"
        nzStatus="error"
        i18n-nzSubTitle
        nzSubTitle="There was a problem during processing your vocabulary list, you can start over">
        <div nz-result-extra>
          <button nz-button nzType="default" routerLink="/library" i18n>Back to Library</button>
          <button nz-button nzType="danger" (click)="reset()" i18n>Start Over</button>
        </div>
      </nz-result>
    </div>
  </div>
</ng-template>

<ng-template #errorProcessingRef>
  <div class="message-layout">
    <div>
      <nz-result
        i18n-nzTitle
        nzTitle="Processing Failed"
        nzStatus="error"
        i18n-nzSubTitle
        nzSubTitle="There was a problem during processing of the document, you can delete it and try again">
        <div nz-result-extra>
          <button nz-button nzType="default" routerLink="/library" i18n>Back to Library</button>
          <button nz-button nzType="danger" (click)="deleteDocument()" i18n>Delete document</button>
        </div>
      </nz-result>
    </div>
  </div>
</ng-template>

<ng-template #errorWordRef>
  <div class="message-layout">
    <div>
      <nz-result
        i18n-nzTitle
        nzTitle="Error"
        nzStatus="warning"
        i18n-nzSubTitle
        nzSubTitle="There was a problem during while getting your document">
        <div nz-result-extra>
          <button nz-button nzType="default" routerLink="/library" i18n>Back to Library</button>
          <button nz-button nzType="primary" (click)="reloadDocument()" i18n>Try again</button>
        </div>
      </nz-result>
    </div>
  </div>
</ng-template>

<ng-template #uploadRef>
  <div class="form-content">
    <div>
      <nz-progress [nzPercent]="uploadProgress$ | async" nzType="circle"></nz-progress>
      <p nz-typography nzType="secondary" style="margin-top: 10px;">
        <ng-container *ngIf="(uploadProgress$ | async) < 100.0; else uploadCompletedRef" i18n>Your file is being
          uploaded...
        </ng-container>
        <ng-template #uploadCompletedRef>Your upload was successful! The file will be processed...</ng-template>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #processingRef>
  <div class="form-content">
    <div>
      <nz-progress [nzPercent]="processingDocument$ | async" nzType="circle"></nz-progress>
      <p nz-typography nzType="secondary" style="margin-top: 10px;">
        <ng-container *ngIf="(processingDocument$ | async) < 100.0; else processingCompletedRef" i18n>
          Your vocabulary list is being created...
        </ng-container>
        <ng-template #processingCompletedRef>
          <ng-container i18n>Vocabulary has been created! Redirecting...</ng-container>
        </ng-template>
      </p>
    </div>
  </div>
</ng-template>
