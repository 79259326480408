<div class="topbar">
  <button nz-button (click)="back()">
    <i nz-icon nzType="left"></i> Back
  </button>

  <button nz-button (click)="remove()" style="float: right; color: #b3b3b3;">
    <i nz-icon nzType="delete"></i>
  </button>
</div>

<div class="character">{{character.literal}}</div>
<div class="side-info">
<div class="strokes" i18n><strong>{{character.strokeCount}}</strong> strokes</div>
<div class="frequency" *ngIf="character.freq" i18n>Frequency: <strong>{{character.freq}}</strong></div>
<div class="jlpt">
  <ng-container *ngIf="character.jlpt; else noJLPT" i18n>JLPT <strong>N{{character.jlpt}}</strong></ng-container>
  <ng-template #noJLPT><ng-container i18n>This is not an JLPT kanji</ng-container></ng-template>
</div>
<div class="grade">
  <ng-container *ngIf="character.grade; else noJoyo" i18n>Jōyō kanji, taught in grade <strong>{{character.grade}}</strong></ng-container>
  <ng-template #noJoyo><ng-container i18n>This is not a Jōyō kanji</ng-container></ng-template>
</div>
<div class="radical" i18n>Kangxi radical: <strong>{{character.radicalClassic}}</strong></div>
</div>

<div class="main-info">
  <div class="" i18n><strong>On:</strong> {{character.readingsOn | concat}}</div>
  <div class="" i18n><strong>Kun:</strong> {{character.readingsKun | concat}}</div>
  <div class="" i18n><strong>Nanori:</strong> {{character.readingsNanori | concat}}</div>
  <div class="meaning">{{character.meanings | concat}}</div>
</div>

<ng-container *ngIf="character.compounds.length > 0">
  <h3 i18n>Compounds</h3>
  <ul class="compounds-info">
    <li *ngFor="let compound of character.compounds">
      <ruby>
        {{compound.writing}}
        <rt>{{compound.reading}}</rt>
      </ruby>
      : {{compound.meaning | concat}}
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="character.occurrences.length > 0">
  <h3 i18n>Occurrences</h3>
  <ul class="occurrences-info">
    <li *ngFor="let occurrence of character.occurrences" [innerHtml]="occurrence | hl : character.literal"></li>
  </ul>
</ng-container>
