import {Component, OnInit} from '@angular/core';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {merge} from 'rxjs';
import {GeneralStateQuery, GeneralStateService} from './model/general-state';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {PreferencesService} from './model/preferences';
import {DocumentListQuery} from './views/document-list/state/document/document-list.query';
import {DocumentListService} from './views/document-list/state/document/document-list.service';
import {DocumentQuery} from './views/document/state/document.query';
import {DocumentService} from './views/document/state/document.service';
import {UserQuery} from './model/user';
import {RouterQuery} from '@datorama/akita-ng-router-store';

@Component({
  selector: 'lng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn$ = this.userQuery.selectIsLoggedIn();
  isSidebarCollapsed$ = this.generalStateQuery.select('sidebarCollapsed');

  constructor(private routerQuery: RouterQuery,
              private userQuery: UserQuery,
              private documentQuery: DocumentQuery,
              private documentListQuery: DocumentListQuery,
              private documentService: DocumentService,
              private documentListService: DocumentListService,
              private generalStateService: GeneralStateService,
              private preferenceService: PreferencesService,
              private generalStateQuery: GeneralStateQuery,
              private title: Title,
              private domSanitizer: DomSanitizer,
              private router: Router) {
  }

  ngOnInit() {
    this.preferenceService.loadPreferences();
    this.hookTitleChange();
    this.generalStateService.hookViewModeSubscription();
  }

  private hookTitleChange() {
    // @ts-ignore
    merge(
      this.documentQuery.selectLoading().pipe(filter(isLoading => isLoading), map(isLoading => 'Languer - Loading...')),
      this.documentQuery.select(state => state).pipe(filter(document => document.id != null), map(document => `Languer - ${document.caption}`)),
      this.documentListQuery.selectActiveId().pipe(filter(id => id == null), map(id => 'Languer')),
      this.routerQuery.select().pipe(
        distinctUntilChanged((x: any, y: any) => x.navigationId == y.navigationId),
        filter((store: any) => store.navigationId != null),
        filter((store: any) => !store.state.url.startsWith('/documents')),
        map(() => 'Languer'))
    ).subscribe(title => this.title.setTitle(title));
  }
}
