import {EntityState} from '@datorama/akita';
import {Word} from '../../../document/word-list/state/word-list.model';
import {DocumentLanguage} from '../../../text-document-form/state/document-form.model';
import {Character} from '../../../document/word-details/state/character/character.model';
import {ImportedDocumentStub} from '../../../document-list/state/document-stub/document-stub.model';

export enum EbookWordStatus {
  Pending= 0,
  Rejected=1,
  Accepted=2
}

export interface EbookWord {
  id: number;
  wordId: string;
  status: EbookWordStatus;
  contexts: string[];
  // this is generated on the front end side
  documentCaptions: string[];
}

export type EbookWordExtended = EbookWord & Word

export interface Ebook extends ImportedDocumentStub {
  authors: string;
  asin: string;
  // this is returned in the response but should be moved to Characters store
  characters: Character[]
}

export interface EbookWordState extends EntityState<EbookWord> {
  document: Ebook|null;
  step: number;
}
