import {Pipe, PipeTransform} from '@angular/core';
import {DOCUMENT_LANGUAGE_NAMES, DocumentLanguage} from '../../views/text-document-form/state/document-form.model';

@Pipe({
  name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {
  transform(value: DocumentLanguage, ...args: unknown[]): string {
    return DOCUMENT_LANGUAGE_NAMES[value] || value;
  }
}
