import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserQuery, UserService} from '../../model/user';

@Injectable()
export class IsLoggedIn implements CanActivate {

  constructor(protected userQuery: UserQuery, protected router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isLoggedIn: boolean = this.userQuery.isLoggedIn();

    if (!isLoggedIn)
      this.router.navigate(['/login']);

    return isLoggedIn;
  }
}


@Injectable()
export class IsNotLoggedIn extends IsLoggedIn implements CanActivate {
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return !this.userQuery.isLoggedIn();
  }
}
