import {Component, OnInit} from '@angular/core';
import {UserQuery, UserService} from '../../model/user';
import {Observable} from 'rxjs';
import {GeneralStateQuery, GeneralStateService} from '../../model/general-state';
import {environment} from '../../../environments/environment';
import {resetStores} from '@datorama/akita';
import {DocumentListQuery} from '../../views/document-list/state/document/document-list.query';
import {DocumentService} from '../../views/document/state/document.service';
import {DocumentQuery} from '../../views/document/state/document.query';

@Component({
  selector: 'lng-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  newDocument$: Observable<boolean>;
  loggedIn$: Observable<boolean>;
  PRODUCTION: boolean = environment.production;
  currentLanguage = 'Japanese';
  isCollapsed$ = this.generalStateQuery.select('sidebarCollapsed');

  constructor(private userService: UserService,
              private userQuery: UserQuery,
              private documentService: DocumentService,
              private documentListQuery: DocumentListQuery,
              private documentQuery: DocumentQuery,
              private generalStateQuery: GeneralStateQuery,
              private generalStateService: GeneralStateService) {
  }

  ngOnInit() {
    this.loggedIn$ = this.userQuery.selectIsLoggedIn();
  }

  logout() {
    this.userService.logOut();
  }

  toggleCollapse() {
    this.generalStateService.toggleCollapse();
  }

  resetState() {
    resetStores();
    location.reload();
  }
}
