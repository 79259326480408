import {ActivatedRoute, ActivationStart, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Route, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {Component, OnDestroy} from '@angular/core';
import {HashMap} from '@datorama/akita';
import {
  FormControl as NgFormControl,
  FormGroup as NgFormGroup,
  ValidatorsModel
} from '@ng-stack/forms';

import {
  FormControl as AngularFormControl,
  FormGroup as AngularFormGroup,
} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';


export type UrlMatcherFunction = (segments: Array<UrlSegment>, segmentGroup: UrlSegmentGroup, route: Route) => UrlMatchResult;

export function makeComplexUrlMatcher(paramName: string, regex: RegExp): UrlMatcherFunction {
  return (segments: Array<UrlSegment>, segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult => {
    const parts = [regex];
    const posParams: { [key: string]: UrlSegment } = {};
    const consumed: UrlSegment[] = [];

    let currentIndex = 0;

    for (const part of parts) {
      if (currentIndex >= segments.length) {
        // workaround https://github.com/angular/angular/issues/18303
        return null as any;
      }

      const current = segments[currentIndex];

      if (!part.test(current.path)) {
        // workaround https://github.com/angular/angular/issues/18303
        return null as any;
      }

      posParams[paramName] = current;
      consumed.push(current);
      currentIndex++;
    }

    if (route.pathMatch === 'full' &&
      (segmentGroup.hasChildren() || currentIndex < segments.length)) {
      // workaround https://github.com/angular/angular/issues/18303
      return null as any;
    }

    return {consumed, posParams};
  };
}

@UntilDestroy()
@Component({template: ''})
export abstract class RoutableComponent implements OnDestroy {
  protected constructor(protected route: ActivatedRoute, protected router: Router) {}

  extractParamAsInt(paramName: string): Observable<number> {
    return this.extractParams(paramName).pipe(map(param => +param), filter(param => !isNaN(param)));
  }

  extractParams(paramName: string): Observable<string> {
    return this.route.params.pipe(untilDestroyed(this), map(params => params[paramName]));
  }

  navigatedAway(): Observable<any> {
    return this.router.events.pipe(untilDestroyed(this), map((event) => {
      return (event instanceof ActivationStart);
    }), filter(isNavigatingAway => isNavigatingAway));
  }

  ngOnDestroy(): void {
  }
}

export type ServerApiError = HashMap<string[]> | { __general__: string[] }

export function disableEnableForm(disable: boolean, form: NgFormGroup<any> | NgFormControl<any> | AngularFormControl | AngularFormGroup) {
  if (disable) {
    form.disable();
  } else {
    form.enable();
  }
}

export function connectErrorsToForm(errors: ServerApiError | null, form: AngularFormGroup) {
  if (errors == null) {
    form.setErrors({});
    return;
  }

  console.log('setting errors', errors, form);

  Object.entries(errors).filter(([key, value]: [string, string[]]) => form.get(key) != null || key == '__general__')
    .forEach(([key, value]: [string, string[]]) => {
      console.log(key, value);
      if (key === '__general__') {
        form.setErrors({__general__: value}, {emitEvent: true});
        form.markAsTouched();
      } else {
        form.get(key).setErrors({server: value}, {emitEvent: true});
        form.get(key).markAsTouched();
      }
    });
}


export class LanguerValidatorsModel extends ValidatorsModel {
  server: string[]; //messages
  __general__: string[];
}

export class FormGroup<T extends object = any> extends NgFormGroup<T, LanguerValidatorsModel> {}

export class FormControl<T = any> extends NgFormControl<T, LanguerValidatorsModel> {}


export function validateAllFormFields(formGroup: NgFormGroup<any>) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({onlySelf: true});
      control.markAsDirty({onlySelf: true});
      control.updateValueAndValidity()
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
  });

  // if (updateFormManager != null && environment.hmr) {
  //   (updateFormManager.fm as any).updateStore(updateFormManager.formKey, formGroup);
  // }
}
