<ext-generic-modal [buttonX]="true" [modalId]="registeredId">
  <div class="s4e-modal-header">Dummy Component</div>
  <div class="s4e-modal-body">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eleifend sit amet lacus in luctus. Fusce et mi at odio
    tristique porttitor. Nunc pretium, magna eget luctus rutrum, justo risus bibendum ex, vitae dignissim urna leo vel
    tellus. Nam consequat tristique arcu eleifend suscipit. Duis dolor felis, luctus non bibendum et,
  </div>
  <div class="s4e-modal-footer">
    <button class="button button--primary" type="submit" (click)="dismiss()" i18n>Ok</button>
  </div>
</ext-generic-modal>
