import {AfterViewInit, Component, HostListener, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {HashMap, ID} from '@datorama/akita';
import {FormBuilder} from '@angular/forms';
import {Word} from './state/word-list.model';
import {WordListQuery} from './state/word-list.query';
import {WordListService} from './state/word-list.service';
import {map, tap} from 'rxjs/operators';
import {CharacterQuery} from '../word-details/state/character/character.query';
import {CharacterService} from '../word-details/state/character/character.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {DOCUMENT} from '@angular/common';


@UntilDestroy()
@Component({
  selector: 'lng-word-list',
  templateUrl: './word-list.component.html',
  styleUrls: ['./word-list.component.scss']
})
export class WordListComponent implements OnInit, OnDestroy, AfterViewInit {
  tableHeight: string;
  words$: Observable<Word[]>;
  state: Observable<Word[]>;
  @Input() loading: boolean = true;
  @Input() addingWord: boolean = false;
  @Input() activeWord: string = '';
  @Input() selected: Word = null;
  @Input() documentId: ID | null = null;
  @Input() disabled: boolean = true;
  @Input() editedWord: string | null = null;
  characters: HashMap<{ literal: string, hl: boolean }[]> = {};
  trackByContext = (word: Word) => word.context;
  private wordsSize = 0;
  public readonly ITEM_HEIGHT = 39;

  constructor(private wordListQuery: WordListQuery,
              private wordListService: WordListService,
              private characterQuery: CharacterQuery,
              private characterService: CharacterService,
              @Inject(DOCUMENT) private document: Document,
              fb: FormBuilder) {
  }

  ngOnInit() {
    this.words$ = combineLatest(
      this.wordListQuery.selectAll(),
      this.characterQuery.selectAll().pipe(map(chars => chars.map(c => c.literal)))
    ).pipe(tap(([words, characters]) => {
      this.characters = {};
      words.forEach(word => {
        this.characters[word.context] = word.writing.split('').map(w => ({literal: w, hl: characters.includes(w)}));
      });
    }), map(([words, characters]) => words), tap(words => {
      this.wordsSize = words.length;
      this.recalculateTableSize();
    }));

    this.recalculateTableSize();
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
  }

  removeWord(word: Word) {
    this.wordListService.removeWord(word.context);
  }

  activateWord(row: Word) {
    this.wordListService.setActive(row.context);
  }

  deactivateWord(row: Word) {
    this.wordListService.setActive(null);
  }

  edit(element: Word) {
    this.wordListService.editWord(element.context);
  }

  cancelEdit() {
    this.wordListService.cancelEditWord();
    this.characterService.setActive(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.recalculateTableSize();
  }

  private recalculateTableSize() {
    const maxHeight = this.document.body.scrollHeight - (this.ITEM_HEIGHT + 2);
    if (this.wordsSize * this.ITEM_HEIGHT < maxHeight) {
      this.tableHeight = null;
    } else {
      this.tableHeight = `${maxHeight}px`;
    }
  }
}
