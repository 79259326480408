import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserQuery, UserService, UserStore} from './user';
import {PreferencesQuery, PreferencesService, PreferencesStore} from './preferences';
import {GeneralStateQuery, GeneralStateService, GeneralStateStore} from './general-state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    UserQuery,
    UserService,
    UserStore,
    PreferencesQuery,
    PreferencesService,
    PreferencesStore,
    GeneralStateQuery,
    GeneralStateService,
    GeneralStateStore
  ]
})
export class ModelModule {
}
