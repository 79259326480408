import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WordListComponent} from './word-list.component';
import {ReactiveFormsModule} from '@angular/forms';
import {WordListStore} from './state/word-list.store';
import {WordListQuery} from './state/word-list.query';
import {WordListService} from './state/word-list.service';
import {NotificationModule} from '../../../modules/notification/notification.module';
import {NotificationClazz} from '../../../modules/notification/notification.tokens';
import {DuplicateWordNotificationComponent} from './duplicate-word-notification/duplicate-word-notification.component';
import {RouterModule} from '@angular/router';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    WordListComponent,
    DuplicateWordNotificationComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NotificationModule,
    RouterModule,
    NzTableModule,
    NzLayoutModule,
    NzIconModule,
    NzDividerModule,
    NzToolTipModule,
  ],
  providers: [
    WordListService,
    WordListQuery,
    WordListStore,
    {
      provide: NotificationClazz,
      useValue: {name: 'DuplicateWordNotificationComponent', component: DuplicateWordNotificationComponent},
      multi: true
    },
  ],
  exports: [
    WordListComponent,
    DuplicateWordNotificationComponent
  ],
  entryComponents: [
    DuplicateWordNotificationComponent
  ],
})
export class WordListModule {
}
