import {Injectable} from '@angular/core';
import {WordListStore} from './word-list.store';
import {WordListQuery} from './word-list.query';
import {HttpClient} from '@angular/common/http';
import {action} from '@datorama/akita';
import {Word} from './word-list.model';

@Injectable({providedIn: 'root'})
export class WordListCharacterService {
  constructor(private wordListStore: WordListStore,
              private wordListQuery: WordListQuery,
              protected http: HttpClient) {
  }

  @action('addCharacter')
  addCharacter(character: string) {
    const words = this.wordListQuery.getAll().filter(word => word.availableCharacters.includes(character) && !word.characters.includes(character));
    words.forEach(word => this.wordListStore.upsert(word.context, (state: Word) => ({
      ...state,
      characters: [...state.characters, character]
    })));
  }

  @action('removeCharacter')
  removeCharacter(character: string) {
    const words = this.wordListQuery.getAll().filter(word => word.characters.includes(character));
    words.forEach(word => this.wordListStore.upsert(word.context, (state: Word) => ({
      ...state,
      characters: state.characters.filter(c => c != character)
    })));
  }
}
