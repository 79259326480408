import {Store, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Word} from '../word-list/state/word-list.model';
import {Character} from '../word-details/state/character/character.model';

export enum DocumentStatus {
  DONE=1,
  PROCESSING=0,
  CUSTOMIZING=2,
  ERROR=3
}

export interface DocumentState {
  caption: string;
  content: string;
  id: number;
  category: string,
  tags: string[],
  state: DocumentStatus;
  characters: Character[],
  format: 'html'|'markdown'|'video';
  language: string;
  source: string;
  words: Word[];
  locked: boolean;
  type_name: 'video'|'text';
  akitaForm: any;
  progress: number;
  allowWordList: boolean;
  imported: boolean;
  stub: boolean;
}

export interface DocumentStateForm extends DocumentState{
  video: string|undefined;
  subtitles: string|undefined;
}

export function createInitialState(): DocumentState {
  return {
    state: DocumentStatus.DONE,
    caption: '',
    content: '',
    category: null,
    characters: [],
    tags: [],
    id: null,
    akitaForm: undefined,
    locked: true,
    format: 'markdown',
    type_name: 'text',
    language: 'ja-jpan',
    source: '',
    words: [],
    progress: 1.0,
    allowWordList: false,
    imported: false,
    stub: false
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'Document' })
export class DocumentStore extends Store<DocumentState> {

  constructor() {
    super(createInitialState());
  }
}



