import {Injectable} from '@angular/core';
import {setLoading} from '@datorama/akita';
import {HttpClient, HttpEventType, HttpProgressEvent} from '@angular/common/http';
import {EBookStore} from './ebook-stub.store';
import {EBookStub} from './ebook-stub.model';
import {Observable} from 'rxjs';
import {setError} from '../../../../utils/rxjs/akita';
import {last, map, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EbookWordStore} from '../word/ebook-word.store';
import {DocumentListStore} from '../../../document-list/state/document/document-list.store';
import {DocumentStatus} from '../../../document/state/document.store';
import {Ebook} from '../word/ebook-word.model';
import {DocumentStubService} from '../../../document-list/state/document-stub/document-stub.service';
import {ImportedDocumentStub} from '../../../document-list/state/document-stub/document-stub.model';

@Injectable({providedIn: 'root'})
export class EBookService {

  constructor(private store: EBookStore,
              private wordStore: EbookWordStore,
              private documentStubService: DocumentStubService,
              private router: Router,
              private http: HttpClient) {
  }

  checkVocabularyDB(vocabularyFile: File): Observable<EBookStub[]> {
    const formData = new FormData();
    formData.set('vocabulary', vocabularyFile, vocabularyFile.name);
    for (const [k, value] of Object.entries(document)) {
      formData.set(k, Array.isArray(value) ? JSON.stringify(value) : value);
    }

    this.store.update({uploadProgress: 0.0});

    return this.http.post<any>('/api/kindle/import/check/', formData, {reportProgress: true, observe: 'events'})
      .pipe(
        setLoading(this.store),
        setError(this.store),
        tap((event: HttpProgressEvent) => (event.type === HttpEventType.UploadProgress)
          && this.store.update(state => ({
            ...state,
            uploadProgress: Math.round((event.loaded / event.total) * 100.0)
          }))),
        last(),
        map((event: any) => event.body),
        tap(() => this.store.update({uploadProgress: 100.0})),
        tap(data => this.store.set(data)),
        tap(data => this.wordStore.update({step: 1})),
      ) as Observable<EBookStub[]>;
  }

  setActive(ebook: EBookStub) {
    this.store.setActive(ebook.id);
  }

  createEbook(ebookId: string, vocabularyFile: File, ebookFile: File|null = null) {
    const formData = new FormData();
    formData.set('vocabulary', vocabularyFile, vocabularyFile.name);
    formData.set('ebook_id', ebookId);

    this.store.set([]);
    this.wordStore.set([]);

    this.store.update({uploadProgress: 0.0});

    return this.http.post<ImportedDocumentStub>('/api/kindle/import/', formData, {reportProgress: true, observe: 'events'})
      .pipe(
        setLoading(this.store),
        setError(this.store),
        tap((event: HttpProgressEvent) => (event.type === HttpEventType.UploadProgress)
          && this.store.update(state => ({
            ...state,
            uploadProgress: Math.round((event.loaded / event.total) * 100.0)
          }))),
        last(),
        map((event: any) => event.body as Ebook),
        tap(() => this.store.update({uploadProgress: 100.0})),
        tap(document => {
          this.wordStore.update({document: document});
          this.documentStubService.add(document);
        }),
        switchMap(document => this.router.navigate([], {queryParams: {id: document.id}}))
      );
  }

  reset() {
    this.store.reset()
  }
}
