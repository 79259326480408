import {Injectable} from '@angular/core';
import {EntityStore, guid, StoreConfig} from '@datorama/akita';
import {Modal, ModalState} from './modal.model';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'ExtModal'})
export class ModalStore extends EntityStore<ModalState, Modal> {
  constructor() {
    console.log('modal-store-created')
    super();
  }
}

