import {Component} from '@angular/core';
import {RouterQuery} from '@datorama/akita-ng-router-store';
import {debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DocumentFormQuery} from '../text-document-form/state';
import {GeneralStateQuery, GeneralStateService} from '../../model/general-state';
import {ActivatedRoute, Router} from '@angular/router';
import {UserQuery} from '../../model/user';
import {RoutableComponent} from '../../services/utils/miscellaneous';
import {DocumentListService} from '../document-list/state/document/document-list.service';
import {DocumentListQuery} from '../document-list/state/document/document-list.query';
import {DocumentState, DocumentStatus} from './state/document.store';
import {DocumentService} from './state/document.service';
import {DocumentQuery} from './state/document.query';
import {Word} from './word-list/state/word-list.model';
import {WordListQuery} from './word-list/state/word-list.query';
import {Character} from './word-details/state/character/character.model';
import {CharacterQuery} from './word-details/state/character/character.query';
import {CharacterService} from './word-details/state/character/character.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NzDrawerRef, NzDrawerService} from 'ng-zorro-antd/drawer';
import {WordListService} from './word-list/state/word-list.service';
import {DetailsComponent} from './details/details.component';

@UntilDestroy()
@Component({
  selector: 'lng-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent extends RoutableComponent {
  public DocumentStatus = DocumentStatus;
  public document$: Observable<DocumentState>;
  public loading$: Observable<boolean>;
  public addingWord$: Observable<boolean>;
  public activeWord$: Observable<string>;
  public listLoading$: Observable<boolean>;
  public loadingForm$: Observable<boolean>;
  public showWordList$: Observable<boolean>;
  public wordLoading$: Observable<boolean>;
  public newDocument$: Observable<boolean>;
  public selectedWord$: Observable<Word>;
  public documentLocked$: Observable<boolean>;
  public newDocument: boolean = false;
  public showDocumentList$: Observable<boolean>;
  public editedWord$: Observable<string | null>;
  public sideContentMode$: Observable<'side' | 'over'>;
  public allowWordList$: Observable<boolean>;
  public showWordDetails$: Observable<boolean>;
  public activeCharacter$: Observable<Character>;
  public words$: Observable<Word[]> = this.wordListQuery.selectAll();
  public processingProgress: number = 0.0;
  public error$ = this.documentQuery.selectError();
  private drawerRef: NzDrawerRef = null;

  constructor(private routerQuery: RouterQuery,
              private wordListService: WordListService,
              private documentService: DocumentService,
              private generalStateService: GeneralStateService,
              private wordListQuery: WordListQuery,
              private generalStateQuery: GeneralStateQuery,
              private documentListQuery: DocumentListQuery,
              private documentFormQuery: DocumentFormQuery,
              private documentQuery: DocumentQuery,
              private userQuery: UserQuery,
              private documentListService: DocumentListService,
              private characterQuery: CharacterQuery,
              private characterService: CharacterService,
              private drawerService: NzDrawerService,
              route: ActivatedRoute,
              router: Router) {
    super(route, router);
  }

  ngOnInit() {
    this.showDocumentList$ = this.generalStateQuery.select(state => state.showDocumentList);
    this.loading$ = this.documentQuery.selectLoading();
    this.documentLocked$ = this.documentQuery.select(document => document.locked);
    this.wordLoading$ = this.wordListQuery.selectLoading();
    this.editedWord$ = this.wordListQuery.select(store => store.editedWordId);
    this.loadingForm$ = this.documentFormQuery.selectLoading();
    this.listLoading$ = this.documentListQuery.selectLoading().pipe(debounceTime(500));
    this.document$ = this.documentQuery.select(state => state);
    this.activeCharacter$ = this.characterQuery.selectActive();

    const currentDocument$ = this.extractParamAsInt('id')
      .pipe(
        tap(id => this.documentListService.setActive(id)),
        switchMap(id => this.documentService.load(id)),
        shareReplay(1)
      );

    currentDocument$.pipe(
      filter(document => document.state === DocumentStatus.PROCESSING),
      switchMap(document => this.documentService.updateProgress(document.id)),
      tap(progress => this.processingProgress = Math.round(progress.progress * 100.0)),
      untilDestroyed(this)
    ).subscribe();

    currentDocument$.pipe(
      switchMap(document => this.route.queryParamMap.pipe(map(query => query.get('edit-metadata')), filter(edit => edit === '1'), map(() => document)))
    ).subscribe(
      (document) => {
        this.documentService.showEditModal(document as any);
        this.router.navigate([], {queryParams: {}, replaceUrl: true});
      }
    );

    this.allowWordList$ = this.documentQuery.allowWordList$();

    this.sideContentMode$ = this.generalStateQuery.select(state => state.viewMode)
      .pipe(map(mode => mode == 'mobile' ? 'over' : 'side'));


    this.selectedWord$ = this.wordListQuery.selectActive();
    this.addingWord$ = this.wordListQuery.select(state => state.addingWord);
    this.activeWord$ = this.wordListQuery.select(state => state.activeWord);

    this.showWordDetails$ = this.documentQuery.showWordDetails$();
  }

  public setCloseDocumentList(show: boolean) {
    if (show === false) {
      this.generalStateService.showDocumentList(false);
    }
  }

  public deleteDocument() {
    this.documentService.deleteActive();
  }

  private createWordDetailsDrawer(editedWord: Word) {
    this.drawerRef = this.drawerService.create({
      nzContent: DetailsComponent,
      nzWidth: '400px',
      nzCloseOnNavigation: true,
      nzClosable: false,
    });

    this.drawerRef.afterClose.subscribe(() => this.wordListService.cancelEditWord());
  }
}
