import { EditorStore } from './editor.store';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EditorService {
  constructor(private editorStore: EditorStore) {}

  clear() {
    this.editorStore.update(state => ({...state, content: ''}))
  }
}
