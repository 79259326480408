import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Store} from '@datorama/akita/lib/store';
import {Query} from '@datorama/akita';
import {FormGroup} from '@angular/forms';
import {connectErrorsToForm, ServerApiError} from '../../services/utils/miscellaneous';
import {logIt} from './operators';

export function setError(store: Store, rethrow: boolean = true): <T>(source: Observable<T>) => Observable<T|null> {
  store.setError(null);
  return (source) => source.pipe(catchError(error => {
    store.setError(error);
    return rethrow ? throwError(error) : of(null);
  }));

}

export class FormConnector<T> {
  constructor(private query: Query<T>) {}

  public connect(form: FormGroup): Observable<ServerApiError | null> {
    console.log('connect')
    return this.query.selectError<ServerApiError | null>()
      .pipe(
        logIt('form-connector'),
        tap(error => connectErrorsToForm(error, form))
      );
  }
}
