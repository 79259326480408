import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hlOccurrences'
})
export class HLOccurrencesPipe implements PipeTransform {
  transform(context: string, occurrences: string[]): string {
    occurrences = [...occurrences].sort().reverse();
    occurrences.forEach(occurrence => {
      context = context.split(occurrence).join(`<span class="word-occurrence">${occurrence}</span>`);
    });
    return context;
  }

}
