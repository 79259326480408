import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Notification } from './notification.model';

export interface NotificationState extends EntityState<Notification> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Notification', resettable: true })
export class NotificationStore extends EntityStore<NotificationState, Notification> {
  constructor() { super(); }
}

