import {createInitialState, DocumentState} from '../../document/state/document.store';

export type DocumentLanguage = 'ja-jpan' | 'zh-hant' | 'zh-hans';
export type DocumentFormat = 'video' | 'html' | 'markdown';
export const DOCUMENT_LANGUAGE_NAMES = {
  'ja-jpan': $localize`Japanse`,
  'zh-hant': $localize`Chinese (Traditional)`,
  'zh-hans': $localize`Chinese (Simplified)`
}

export interface DocumentFS {
  id: number|null;
  category: string;
  tags: string[];
  caption: string;
  source: string;
  format: DocumentFormat;
  language: DocumentLanguage;
  content: string;
}

export interface DocumentFormState extends DocumentState {
  uploadProgress: number;
  loading: boolean;
  error: any;
}

export function createFormInitialState(): DocumentFormState {
  return {
    ...createInitialState(),
    loading: false,
    error: null,
    uploadProgress: 0.0
  };
}

export interface TagState {
  tag: string
}

export interface TagStoreState {
}
