import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WordDetailsStore } from './word-details.store';

@Injectable({ providedIn: 'root' })
export class WordDetailsService {

  constructor(private wordDetailsStore: WordDetailsStore,
              private http: HttpClient) {
  }

}
