import {ActiveState, EntityState, EntityStore, StoreConfig, transaction} from '@datorama/akita';
import {DocumentStub} from './document-list.model';
import {Injectable} from '@angular/core';
import {SetEntities} from '@datorama/akita/lib/setEntities';

export interface DocumentListState extends EntityState<DocumentStub>, ActiveState<number> {
  activeCategory: string | null;
  categories: string[];
  loadingMetadata: boolean;
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'DocumentStub'})
export class DocumentListStore extends EntityStore<DocumentListState, DocumentStub, number> {

  constructor() {
    super({
      activeCategory: null,
      categories: [],
      loadingMetadata: false
    });
  }

  setActiveCategory(category: string | null) {
    this.update(state => ({...state, activeCategory: category}));
  }

  @transaction()
  set(entities: SetEntities<DocumentStub>, options?: { activeId?: number | null }) {
    super.set(entities, options);
    const cats = [];
    for (let docId in Object.keys(entities)) {
      const cat = entities[docId].category;
      if ((cat || null) !== null && cats.indexOf(cat) === -1) {
        cats.push(cat);
      }

      this.update(state => ({...state, categories: cats}));
    }
  }
}

export const documentListStore = new DocumentListStore();

