<div class="content" *ngIf="isLoading; else contentRef">
  <ng-container>
    <nz-skeleton class="word-skeleton" [nzActive]="true" [nzParagraph]="{rows: 2}" [nzTitle]="false"></nz-skeleton>
    <nz-skeleton class="word-skeleton" [nzActive]="true" [nzParagraph]="{rows: 2}" [nzTitle]="false"></nz-skeleton>
    <nz-skeleton class="word-skeleton" [nzActive]="true" [nzParagraph]="{rows: 2}" [nzTitle]="false"></nz-skeleton>
    <nz-skeleton class="word-skeleton" [nzActive]="true" [nzParagraph]="{rows: 2}" [nzTitle]="false"></nz-skeleton>
    <nz-skeleton class="word-skeleton" [nzActive]="true" [nzParagraph]="{rows: 2}" [nzTitle]="false"></nz-skeleton>
    <nz-skeleton class="word-skeleton" [nzActive]="true" [nzParagraph]="{rows: 2}" [nzTitle]="false"></nz-skeleton>
    <nz-skeleton class="word-skeleton" [nzActive]="true" [nzParagraph]="{rows: 2}" [nzTitle]="false"></nz-skeleton>
  </ng-container>
</div>
<ng-template #contentRef>
  <div style="text-align: center; margin-top: 30px; margin-bottom: 30px; position: relative;">
    <div style="position: absolute; text-align: left; top: 0; width: 550px;">
      <button [disabled]="isLoading" nz-button
              style="display: inline-block; float: left; margin-right: 5px; "
              (click)="deleteDocument.emit()"><i nz-icon nzType="delete"></i></button>
      <h3 style="padding-top: 5px;">{{ebook.caption}}</h3>
    </div>
    <nz-radio-group [formControl]="modeFc" nzButtonStyle="solid">
      <label nz-radio-button [nzValue]="EbookWordStatus.Pending">Pending ({{pendingCount}})</label>
      <label nz-radio-button [nzValue]="EbookWordStatus.Rejected">Rejected ({{rejectedCount}})</label>
      <label nz-radio-button [nzValue]="EbookWordStatus.Accepted">Accepted ({{acceptedCount}})</label>
    </nz-radio-group>
    <div style="position: absolute; right: 5px; top: 0;" nz-tooltip
         [nzTooltipTrigger]="pendingCount > 0 ? 'hover' : null"
         i18n-nzTooltipTitle
         nzTooltipTitle="You must either accept or reject all pending words befor finalizing document">
      <button nz-button nzType="default"
              [disabled]="pendingCount > 0" (click)="finalizeDocument.emit()">Finish configuration
      </button>
    </div>
  </div>

  <div style="margin-left: -16px; margin-right: -16px;">
    <nz-table #basicTable
              [ngClass]="{empty: words.length === 0}"
              [nzData]="words"
              [nzFrontPagination]="false" [nzBordered]="true" nzSize="small"
              [nzOuterBordered]="true"
              [nzVirtualItemSize]="39"
              [nzVirtualForTrackBy]="trackById"
              style="height: calc(100vh - 150px)"
              class="word-selection-table"
              nzTableLayout="fixed"
              [nzScroll]="{y: tableHeight, x: tableWidth}">
      <thead>
      <tr>
        <th nzWidth="110px"></th>
        <th nzWidth="30px"></th>
        <th nzWidth="150px">Writing</th>
        <th nzWidth="150px">Reading</th>
        <th nzWidth="280px">Meaning</th>
        <th [nzEllipsis]="true">Context</th>
      </tr>
      </thead>
      <tbody>
        <ng-template nz-virtual-scroll let-word let-index="index">
          <tr [ngClass]="{'machine-translated': word.machineTranslated, 'other-documents': word.documents.length > 0}">
            <td style="text-align: center">
              <a *ngIf="modeFc.value !== EbookWordStatus.Accepted" (click)="acceptWord.emit(word)" i18n-title title="Accept this word"><i nz-icon nzType="check"></i></a>
              <nz-divider *ngIf="modeFc.value === EbookWordStatus.Pending" nzType="vertical"></nz-divider>
              <a *ngIf="modeFc.value !== EbookWordStatus.Rejected" (click)="rejectWord.emit(word)" i18n-title title="Reject this word"><i nz-icon nzType="close"></i></a>
              <nz-divider nzType="vertical"></nz-divider>
              <a (click)="editWord.emit(word)" i18n-title title="Edit this word"><i nz-icon nzType="edit"></i></a>
            </td>
            <td>
                <span *ngIf="word.machineTranslated; else otherWarningsRef" nz-typography nzType="danger">
                  <i nz-icon nzType="warning" nz-tooltip
                     i18n-nzTooltipTitle
                     nzTooltipTitle="This word has been machine translated. Please double check whether it is correct"></i>
                </span>
              <ng-template #otherWarningsRef>
                  <span *ngIf="word.documents.length > 0" nz-typography nzType="danger">
                  <i nz-icon nzType="warning" nz-tooltip
                     i18n-nzTooltipTitle
                     nzTooltipTitle="This word is already present in other documents ({{word.documentCaptions | concat}})"></i>
                </span>
              </ng-template>
            </td>
            <td>
                <span *ngFor="let char of word | word2Characters : characters"
                      nz-tooltip
                      i18n-nzTooltipTitle
                      nzTooltipTitle="Click to add / remove character to characters list"
                      [nzTooltipTrigger]="char.isCharacter ? 'hover' : null"
                      nzTooltipPlacement="right"
                      (click)="char.isCharacter && toggleCharacter.emit(char)"
                      [ngClass]="{'hl-character': char.present, 'word-character': char.isCharacter}">{{char.literal}}</span>
            </td>
            <td>{{word.reading}}</td>
            <td [nzEllipsis]="true">{{word.meaning | concat}}</td>
            <td [nzEllipsis]="true" [innerHTML]="(word.contexts[0] || '') | hlOccurrences : word.occurrences" style="cursor: zoom-in;"
                nz-popover [nzPopoverContent]="contentPopover" [nzPopoverPlacement]="['top', 'bottom']" nzPopoverTrigger="click"></td>
            <ng-template #contentPopover>
              <div style="max-width: 500px;" [innerHTML]="word.contexts | concat : '<br><br>' | hlOccurrences : word.occurrences"></div>
            </ng-template>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
  </div>
</ng-template>
