import { Pipe, PipeTransform } from '@angular/core';
import {ValidatorsModel} from '@ng-stack/forms';

@Pipe({
  name: 'concatErrors'
})
export class ConcatErrorsPipe implements PipeTransform {

  transform(value: ValidatorsModel|null): string {
    console.log(value)
    return Object.values(value ?? {}).join(', ');
  }
}
