import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DocumentLanguage} from '../../views/text-document-form/state/document-form.model';

@Component({
  selector: 'lng-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageSelectComponent),
      multi: true
    }
  ]
})
export class LanguageSelectComponent implements OnInit, ControlValueAccessor {
  disabled: boolean = false;
  value: DocumentLanguage|null = null;
  onChange = (val: DocumentLanguage) => {};
  onTouch = () => {};
  languages: DocumentLanguage[] = ['ja-jpan', 'zh-hant', 'zh-hans'];

  constructor() {
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: DocumentLanguage): void {
    this.value = value;
  }

  change(language: DocumentLanguage) {
    this.value = language
    this.onChange(language);
  }
}
