import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserQuery, UserService} from '../../model/user';
import {Observable} from 'rxjs';
import {
  connectErrorsToForm,
  disableEnableForm,
  FormControl,
  FormGroup,
  ServerApiError, validateAllFormFields
} from '../../services/utils/miscellaneous';
import {Validators} from '@ng-stack/forms';
import {map} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

export interface LoginForm {
  username: string;
  password: string;
}

@UntilDestroy()
@Component({
  selector: 'lng-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup<LoginForm>;
  isLoading$: Observable<boolean>;
  errors$: Observable<string[]>;

  constructor(private userService: UserService, private userQuery: UserQuery) {
    this.form = new FormGroup<LoginForm>({
      username: new FormControl<string>('', [Validators.required, Validators.minLength(1)]),
      password: new FormControl<string>('', [Validators.required, Validators.minLength(1)])
    });
  }

  ngOnInit() {
    this.isLoading$ = this.userQuery.selectLoading();
    this.isLoading$.pipe(untilDestroyed(this)).subscribe(loading => disableEnableForm(loading, this.form));
    this.errors$ = this.userQuery.selectError<ServerApiError>().pipe(map(error => error == null ? [] : error.__general__))
    this.userQuery.selectError().pipe(untilDestroyed(this)).subscribe(error => connectErrorsToForm(error, this.form));
  }

  ngOnDestroy(): void {
  }

  onSubmit() {
    validateAllFormFields(this.form);

    console.log(this.form.controls.password.errors)
    console.log(this.form.controls.username.errors)
    if(this.form.invalid) {return;}

    this.form.setErrors(null);
    this.userService.logIn(this.form.value.username, this.form.value.password)
  }
}
