<div class="logo"></div>
<!--<a href="javascript:undefined" class="minmax-button" (click)="isCollapsed = !isCollapsed">-->
<!--  <i nz-icon nzType="left"></i>-->
<!--</a>-->
<ul nz-menu nzTheme="dark" nzMode="vertical" [nzInlineCollapsed]="isCollapsed$ | async" [nzSelectable]="false">
  <li nz-menu-item routerLink="/library" [nzMatchRouter]="true">
    <i nz-icon nzType="book"></i>
    <span i18n>Library</span>
  </li>
<!--  <li nz-menu-item routerLink="/vocabulary" [nzMatchRouter]="true">-->
<!--    <i nz-icon nzType="book"></i>-->
<!--    <span i18n>Vocabulary</span>-->
<!--  </li>-->
  <li nz-submenu nzIcon="plus-circle" i18n-nzTitle nzTitle="Add Document">
    <ul>
      <li nz-menu-item routerLink="/text/new">
        <i nz-icon nzType="file-text"></i>
        <span i18n>Text</span>
      </li>
      <li nz-menu-item routerLink="/video/import">
        <i nz-icon nzType="video-camera"></i>
        <span i18n>Video</span>
      </li>
      <li nz-menu-item routerLink="/kindle/import">
        <i nz-icon nzType="amazon"></i>
        <span i18n>Kindle</span>
      </li>
    </ul>
  </li>
</ul>
<ul nz-menu nzTheme="dark" nzMode="vertical" class="user-menu" [nzInlineCollapsed]="isCollapsed$ | async" [nzSelectable]="false">
<!--  <li nz-submenu nzIcon="translation" [nzTitle]="currentLanguage">-->
<!--    <ul>-->
<!--      <li nz-menu-item [nzSelected]="currentLanguage === 'Japanese'" (click)="currentLanguage = 'Japanese'">Japanese</li>-->
<!--      <li nz-menu-item [nzSelected]="currentLanguage === 'Korean'" (click)="currentLanguage = 'Korean'">Korean</li>-->
<!--      <li nz-menu-item [nzSelected]="currentLanguage === 'Chinese (Traditional)'" (click)="currentLanguage = 'Chinese (Traditonal)'">Chinese (Traditonal)</li>-->
<!--      <li nz-menu-item [nzSelected]="currentLanguage === 'Chinese (Simplified)'" (click)="currentLanguage = 'Chinese (Simplified)'">Chinese (Simplified)</li>-->
<!--    </ul>-->
<!--  </li>-->

<!--  <li nz-menu-item routerLink="/profile">-->
<!--    <i nz-icon nzType="user"></i>-->
<!--    <span i18n>Profile</span>-->
<!--  </li>-->
  <li nz-menu-item (click)="logout()">
    <i nz-icon nzType="logout"></i>
    <span i18n>Logout</span>
  </li>
  <li nz-menu-item (click)="toggleCollapse()" style="text-align: center;">
    <i nz-icon [nzType]="(isCollapsed$ | async) ? 'right' : 'left'"></i>
  </li>
</ul>
