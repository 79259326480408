import {Store, StoreConfig} from '@datorama/akita';
import {UserState} from './user.model';
import {Injectable} from '@angular/core';

export function createInitialState(): UserState {
  return {
    email: null,
    username: null,
    loading: true,
    error: null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'user'})
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
